var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "add-scales-modal",
      "hide-footer": "",
      "hide-header": "",
      "centered": ""
    }
  }, [_c('div', [_c('div', {
    staticClass: "title-block"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("Добавление товара на весы")]), _c('e-button', {
    on: {
      "click": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/modal_close.svg",
      "alt": ""
    }
  })])], 1)]), _c('div', [!_vm.isEanWeight ? _c('div', {
    staticClass: "form-group barcode-group"
  }, [_c('label', [_vm._v(" Весовой штрих-код "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "b-generate-bc cursor",
    on: {
      "click": _vm.generateBarcode
    }
  }, [_vm._v(" Сгенерировать штрих-код ")]), _c('b-form-input', {
    staticClass: "scan-circle",
    class: {
      error: _vm.barcode_error
    },
    attrs: {
      "autofocus": "",
      "type": "number",
      "required": "",
      "placeholder": "00000000000"
    },
    model: {
      value: _vm.barcode_item.barcode.barcode,
      callback: function ($$v) {
        _vm.$set(_vm.barcode_item.barcode, "barcode", $$v);
      },
      expression: "barcode_item.barcode.barcode"
    }
  }), _c('img', {
    staticClass: "generate-barcode",
    attrs: {
      "src": "/img/icons/icon_scan_circle.svg",
      "alt": "Можно считать сканером"
    }
  }), _vm.barcode_error === 1 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Необходимо ввести штрихкод ")]) : _vm._e(), _vm.barcode_error === 2 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Такой штрихкод уже существует ")]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" Списки "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('e-select', {
    attrs: {
      "placeholder": "Выберите список",
      "error": _vm.scalesError,
      "options": _vm.list_options
    },
    model: {
      value: _vm.selected_scales,
      callback: function ($$v) {
        _vm.selected_scales = $$v;
      },
      expression: "selected_scales"
    }
  })], 1)]), _c('div', {
    staticClass: "footer-block"
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "variant": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" Сохранить ")]), _c('e-button', {
    attrs: {
      "size": "m",
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(" Отмена ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }