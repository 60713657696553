var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "compound-table-card p-0 b-table__wrapper",
    attrs: {
      "no-body": ""
    }
  }, [_c('button', {
    staticClass: "btn add-btn",
    staticStyle: {
      "color": "#0066ff",
      "margin": "15px 15px 15px 0px"
    },
    on: {
      "click": _vm.createCompoundModal
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/plus_blue.svg",
      "alt": "",
      "width": "12",
      "height": "12"
    }
  }), _vm._v(" Добавить ")]), _c('b-table', {
    attrs: {
      "items": _vm.items,
      "fields": _vm.fields
    },
    on: {
      "row-clicked": _vm.showCompoundModal
    },
    scopedSlots: _vm._u([{
      key: "head(id)",
      fn: function () {
        return [_c('span')];
      },
      proxy: true
    }, {
      key: "cell(id)",
      fn: function (data) {
        return [_c('div', [_c('div', {
          staticClass: "b-status",
          class: _vm.get_variant(data.item)
        }), _c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "none"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('more-svg', {
                staticStyle: {
                  "cursor": "pointer"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.copy(data.item);
            }
          }
        }, [_vm._v(" Копировать версию ")]), data.item.status !== 'draft' ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.toArchive(data.item);
            }
          }
        }, [_vm._v(" Архивировать ")]) : _vm._e(), data.item.status === 'draft' ? _c('b-dropdown-item', {
          staticClass: "text-red",
          on: {
            "click": function ($event) {
              return _vm.remove(data.item);
            }
          }
        }, [_vm._v(" Удалить ")]) : _vm._e()], 1)], 1)];
      }
    }, {
      key: "cell(date)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(data.value)) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.get_variant(data.item)
          }
        }, [_vm._v(" " + _vm._s(_vm.get_text(data.item)) + " ")])];
      }
    }])
  })], 1), _c('compound-modal', {
    attrs: {
      "compound": _vm.currentCompound
    },
    on: {
      "refresh_compound": _vm.refresh_compound,
      "refresh_table": _vm.refresh_table
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }