var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "warning-container"
  }, [_c('div', {
    staticClass: "warning-container__left"
  }, [_vm._t("left", function () {
    return [_c('warning-svg', {
      staticClass: "warning-img",
      attrs: {
        "color": "#313131"
      }
    }), _c('p', [_vm._t("title_warning", function () {
      return [_vm._v("Товар отсутствует на весах")];
    })], 2)];
  })], 2), _c('div', {
    staticClass: "warning-container__right"
  }, [_vm._t("right", function () {
    return [_c('e-button', {
      attrs: {
        "size": "m"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('set_skip_warning');
        }
      }
    }, [_vm._v(" Пропустить ")]), _c('e-button', {
      attrs: {
        "size": "m"
      },
      on: {
        "click": function ($event) {
          return _vm.$emit('open_modal_scale');
        }
      }
    }, [_vm._v(" Добавить на весы ")]), _c('b-dropdown', {
      attrs: {
        "no-caret": "",
        "variant": "secondary"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('img', {
            staticClass: "dots-img",
            attrs: {
              "src": "/img/icons/Dots.svg",
              "alt": "dots"
            }
          })];
        },
        proxy: true
      }])
    }, [_c('b-dropdown-item', {
      on: {
        "click": _vm.hide
      }
    }, [_vm._v("Не показывать больше")])], 1)];
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }