var render = function render(){
  var _vm$description;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('plu-product-list-modal', {
    attrs: {
      "list_name": _vm.list_name,
      "list_id": _vm.list_id
    }
  }), _c('add-scales-modal', {
    attrs: {
      "barcodes": _vm.barcodes,
      "product_id": _vm.product_id,
      "product": _vm.product
    },
    on: {
      "add-barcode": _vm.addBarcode,
      "refetch": function ($event) {
        return _vm.$emit('refetch');
      }
    }
  }), _c('div', {
    staticClass: "add-button-block w-100 d-flex justify-content-end"
  }, [_vm.disabled ? _c('b-tooltip', {
    attrs: {
      "target": "button-block"
    }
  }, [_vm._v(" Укажите ед. измерения кг, чтобы добавить весы ")]) : _vm._e(), _c('div', {
    attrs: {
      "id": "button-block"
    }
  }, [_c('e-button', {
    attrs: {
      "variant": _vm.variantAddButton,
      "plus": "",
      "size": "m",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.addScales
    }
  }, [_vm._v(" Добавить ")])], 1)], 1), _c('b-card', {
    staticClass: "p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    attrs: {
      "fields": _vm.fields,
      "items": _vm.product.plu
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function (_ref) {
        var _item$list;

        var item = _ref.item;
        return [_c('div', {
          staticClass: "list-cell p7 d-flex align-items-center",
          on: {
            "click": function ($event) {
              return _vm.openList(item);
            }
          }
        }, [_c('img', {
          staticClass: "mr-2",
          attrs: {
            "src": "/img/icons/list.svg",
            "alt": "Список"
          }
        }), _c('p', {
          staticClass: "m-0"
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$list = item.list) === null || _item$list === void 0 ? void 0 : _item$list.name))])])];
      }
    }, {
      key: "cell(plu)",
      fn: function (data) {
        return [_c('div', {
          staticClass: "p7"
        }, [_vm._v(" " + _vm._s(data.value) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "p7",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              return _vm.removePlu(item);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/bin.svg",
            "alt": "bin"
          }
        })])];
      }
    }])
  })], 1), _c('b-card', {
    staticClass: "p-4",
    attrs: {
      "no-body": ""
    }
  }, [(_vm$description = _vm.description) !== null && _vm$description !== void 0 && _vm$description.id ? _c('div', [_c('div', {
    staticClass: "desc-text"
  }, [_vm._v("Описание товара на весах")]), _c('b-form-textarea', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "rows": "3"
    },
    on: {
      "input": _vm.updateDesc
    },
    model: {
      value: _vm.description.description,
      callback: function ($$v) {
        _vm.$set(_vm.description, "description", $$v);
      },
      expression: "description.description"
    }
  })], 1) : _c('div', {
    staticClass: "btn-add cursor",
    on: {
      "click": _vm.addDesc
    }
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/plus_normal.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить описание ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }