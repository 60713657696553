var render = function render(){
  var _vm$edit_id, _vm$ext_id$external_i2, _vm$ext_id$external_i3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "external-id-modal",
      "centered": ""
    },
    on: {
      "hide": _vm.closeModal,
      "shown": _vm.setMode
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Внешний код контрагента")]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return close();
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function () {
        var _vm$ext_id$ext_quant, _vm$ext_id$ext_quant$, _vm$ext_id$external_i;

        return [_c('div', {
          staticClass: "d-flex w-50"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "disabled": _vm.ext_id.ext_quant < 1 || !((_vm$ext_id$ext_quant = _vm.ext_id.ext_quant) !== null && _vm$ext_id$ext_quant !== void 0 && (_vm$ext_id$ext_quant$ = _vm$ext_id$ext_quant.toString()) !== null && _vm$ext_id$ext_quant$ !== void 0 && _vm$ext_id$ext_quant$.length) || !((_vm$ext_id$external_i = _vm.ext_id.external_id) !== null && _vm$ext_id$external_i !== void 0 && _vm$ext_id$external_i.length)
          },
          on: {
            "click": _vm.saveExt
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('div', [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "supplier-select mb-3"
    }
  }, [_vm._v(" Контрагент ")]), _c('i-select', {
    attrs: {
      "filterable": "",
      "clearable": "",
      "disabled": !!((_vm$edit_id = _vm.edit_id) !== null && _vm$edit_id !== void 0 && _vm$edit_id.id),
      "placeholder": "Выберите контрагента"
    },
    model: {
      value: _vm.ext_id.supplier,
      callback: function ($$v) {
        _vm.$set(_vm.ext_id, "supplier", $$v);
      },
      expression: "ext_id.supplier"
    }
  }, _vm._l(_vm.supplier_list, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Код товара контрагента"), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('b-form-input', {
    class: {
      error: !((_vm$ext_id$external_i2 = _vm.ext_id.external_id) !== null && _vm$ext_id$external_i2 !== void 0 && _vm$ext_id$external_i2.length) && _vm.isChanged
    },
    on: {
      "input": function ($event) {
        _vm.isChanged = true;
      }
    },
    model: {
      value: _vm.ext_id.external_id,
      callback: function ($$v) {
        _vm.$set(_vm.ext_id, "external_id", $$v);
      },
      expression: "ext_id.external_id"
    }
  }), _vm.isChanged && !((_vm$ext_id$external_i3 = _vm.ext_id.external_id) !== null && _vm$ext_id$external_i3 !== void 0 && _vm$ext_id$external_i3.length) ? _c('span', {
    staticClass: "error"
  }, [_vm._v("Обязательное поле")]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Наименование товара контрагента")]), _c('b-form-input', {
    model: {
      value: _vm.ext_id.ext_name,
      callback: function ($$v) {
        _vm.$set(_vm.ext_id, "ext_name", $$v);
      },
      expression: "ext_id.ext_name"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Количество")]), _c('b-form-input', {
    class: {
      error: _vm.ext_id.ext_quant < 1 && !_vm.ext_id.ext_quant.length
    },
    attrs: {
      "type": "number"
    },
    on: {
      "input": function ($event) {
        _vm.isChanged = true;
      }
    },
    model: {
      value: _vm.ext_id.ext_quant,
      callback: function ($$v) {
        _vm.$set(_vm.ext_id, "ext_quant", $$v);
      },
      expression: "ext_id.ext_quant"
    }
  }), _vm.isChanged && _vm.ext_id.ext_quant < 1 && !_vm.ext_id.ext_quant.length ? _c('span', {
    staticClass: "error"
  }, [_vm._v("Количество должно быть больше 0")]) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }