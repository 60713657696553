<template>
  <b-modal
    id="external-id-modal"
    centered
    @hide="closeModal"
    @shown="setMode"
  >
    <template #modal-header="{ close }">
      <h5>Внешний код контрагента</h5>
      <b-button
        class="close"
        @click="close()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div>
      <div class="form-group">
        <label for="supplier-select mb-3"> Контрагент </label>
        <i-select
          v-model="ext_id.supplier"
          filterable
          clearable
          :disabled="!!edit_id?.id"
          placeholder="Выберите контрагента"
        >
          <i-option
            v-for="item in supplier_list"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </i-option>
        </i-select>
      </div>
      <div class="form-group">
        <label>Код товара контрагента<span class="text-danger">*</span></label>
        <b-form-input
          v-model="ext_id.external_id"
          :class="{ error: !ext_id.external_id?.length && isChanged }"
          @input="isChanged = true"
        />
        <span
          v-if="isChanged && !ext_id.external_id?.length"
          class="error"
          >Обязательное поле</span
        >
      </div>
      <div class="form-group">
        <label>Наименование товара контрагента</label>
        <b-form-input v-model="ext_id.ext_name" />
      </div>
      <div class="form-group">
        <label>Количество</label>
        <b-form-input
          v-model="ext_id.ext_quant"
          type="number"
          :class="{
            error: ext_id.ext_quant < 1 && !ext_id.ext_quant.length
          }"
          @input="isChanged = true"
        />
        <span
          v-if="isChanged && ext_id.ext_quant < 1 && !ext_id.ext_quant.length"
          class="error"
          >Количество должно быть больше 0</span
        >
      </div>
    </div>
    <template #modal-footer>
      <div class="d-flex w-50">
        <b-button
          variant="primary"
          :disabled="ext_id.ext_quant < 1 || !ext_id.ext_quant?.toString()?.length || !ext_id.external_id?.length"
          @click="saveExt"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="closeModal"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'ExternalIdModal',
    props: {
      product_id: {
        type: String,
        default: ''
      },
      supplier_list: {
        type: Array,
        default: () => []
      },
      edit_id: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        ext_id: {
          supplier: '',
          ext_name: '',
          external_id: '',
          ext_quant: 1
        },
        isChanged: false
      }
    },
    methods: {
      saveExt() {
        if (!this.product_id && !this.edit_id) {
          this.$emit('add_external', { ...this.ext_id, id: new Date().toJSON() })
          return
        }
        if (!this.product_id && this.edit_id) {
          this.$emit('add_external', { ...this.ext_id })
          return
        }
        this.ext_id.id
          ? this.$apollo
              .mutate({
                mutation: require('../gql/UpdateExternalId.graphql'),
                variables: {
                  ...this.ext_id,
                  ext_quant: Number(this.ext_id.ext_quant)
                }
              })
              .then(() => {
                this.closeModal()
                this.$emit('created')
              })
          : this.$apollo
              .mutate({
                mutation: require('../gql/CreateExternalId.graphql'),
                variables: {
                  ...this.ext_id,
                  supplier: this.ext_id.supplier?.length ? this.ext_id.supplier : null,
                  ext_quant: Number(this.ext_id.ext_quant),
                  product: this.$route.params.id
                }
              })
              .then(() => {
                this.closeModal()
                this.$emit('created')
              })
      },
      setMode() {
        if (this.edit_id?.id) {
          this.ext_id = JSON.parse(JSON.stringify(this.edit_id))
        }
      },
      closeModal() {
        this.ext_id = {
          supplier: '',
          ext_name: '',
          external_id: '',
          ext_quant: 1
        }
        if (this.edit_id?.id) this.$emit('close-edit')
        this.$bvModal.hide('external-id-modal')
      }
    }
  }
</script>

<style scoped></style>
