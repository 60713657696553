<template>
  <div>
    <b-card
      class="compound-table-card p-0 b-table__wrapper"
      no-body
    >
      <button
        class="btn add-btn"
        style="color: #0066ff; margin: 15px 15px 15px 0px"
        @click="createCompoundModal"
      >
        <img
          src="/img/icons/plus_blue.svg"
          alt=""
          width="12"
          height="12"
          class="mr"
        />
        Добавить
      </button>
      <b-table
        :items="items"
        :fields="fields"
        @row-clicked="showCompoundModal"
      >
        <template #head(id)>
          <span></span>
        </template>
        <template #cell(id)="data">
          <div>
            <div
              class="b-status"
              :class="get_variant(data.item)"
            ></div>

            <b-dropdown
              no-caret
              variant="none"
            >
              <template #button-content>
                <more-svg style="cursor: pointer" />
              </template>
              <b-dropdown-item @click="copy(data.item)"> Копировать версию </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status !== 'draft'"
                @click="toArchive(data.item)"
              >
                Архивировать
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status === 'draft'"
                class="text-red"
                @click="remove(data.item)"
              >
                Удалить
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
        <template #cell(date)="data">
          {{ formatDate(data.value) }}
        </template>
        <template #cell(status)="data">
          <b-badge :variant="get_variant(data.item)">
            {{ get_text(data.item) }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <compound-modal
      :compound="currentCompound"
      @refresh_compound="refresh_compound"
      @refresh_table="refresh_table"
    />
  </div>
</template>

<script>
  import CompoundModal from '@/views/manufacture/components/CompoundModal'
  import MoreSvg from '@/views/manufacture/components/icon/MoreSvg'
  import { CompoundModel } from '@/models/compound.model'

  export default {
    name: 'CompoundTable',
    components: { CompoundModal, MoreSvg },
    props: {
      product: String
    },
    apollo: {
      Compounds: {
        query: require('../gql/Compounds.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              product: this.product
            }
          }
        },

        result({ data }) {
          if (!data) return
          this.items = data.Compounds?.map((el) => new CompoundModel(el))
        },
        skip() {
          return !this.product
        }
      }
    },
    data() {
      return {
        selected: [],
        currentCompound: new CompoundModel(),
        fields: [
          {
            key: 'id',
            thStyle: 'width: 50px'
          },
          {
            key: 'date',
            label: 'Дата начала'
          },
          {
            key: 'name',
            label: 'Наименование'
          },
          {
            key: 'value',
            label: 'Норма закладки'
          },
          {
            key: 'status',
            label: 'Статус'
          }
        ],
        items: []
      }
    },
    methods: {
      get_variant(item) {
        if (item.status === 'active' && new Date(item.date) > new Date()) return 'warning'
        if (item.status === 'active') return 'primary'
        else if (item.status === 'draft') return 'draft'
        else if (item.status === 'archive') return 'draft'
        return 'draft'
      },
      get_text(item) {
        if (item.status === 'active' && new Date(item.date) > new Date()) return 'Ожидает'
        if (item.status === 'active') return 'Активный'
        else if (item.status === 'draft') return 'Черновик'
        else if (item.status === 'archive') return 'Архив'
        return 'Черновик'
      },
      async showCompoundModal(item) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/Compound.graphql'),
          variables: {
            input: { id: item.id }
          }
        })
        this.currentCompound = new CompoundModel(data.Compound)
        this.$bvModal.show('compound-modal')
      },
      async createCompoundModal() {
        if (!this.product) {
          this.$emit('handlerProduct', true, () => this.queryCompound())
        } else {
          await this.queryCompound()
        }
      },
      async queryCompound() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateCompound.graphql'),
          variables: {
            input: {
              product: this.product,
              date: new Date(),
              value: 1,
              write_on_outgoing: true
            }
          }
        })

        this.currentCompound = new CompoundModel(data.CreateCompound)
        this.$bvModal.show('compound-modal')
        this.$apollo.queries.Compounds.refresh()
      },
      async refresh_compound(cb) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/Compound.graphql'),
          variables: {
            input: { id: this.currentCompound.id }
          }
        })
        this.currentCompound = new CompoundModel(data.Compound)
        if (cb) cb()
      },

      async refresh_table() {
        this.$apollo.queries.Compounds.refresh()
      },
      formatDate(date) {
        return new Intl.DateTimeFormat('ru-RU').format(new Date(date))
      },
      toArchive(item) {
        item.setStatus('archive')
        this.updateCompound(item)
      },
      updateCompound(compound) {
        this.$apollo.mutate({
          mutation: require(`../gql/UpdateCompound.graphql`),
          variables: {
            input: compound.input
          }
        })
      },
      async remove(compound) {
        await this.$apollo.mutate({
          mutation: require(`../gql/RemoveCompound.graphql`),
          variables: {
            input: compound.input
          }
        })
        this.$apollo.queries.Compounds.refresh()
      },
      async copy(item) {
        const coumound = await this.$apollo.query({
          query: require('../gql/Compound.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: { id: item.id }
          }
        })
        const new_item = new CompoundModel(coumound.data.Compound)
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/CreateCompound.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              product: new_item.product.id,
              name: new_item.name + ' (копия)',
              date: new Date(),
              value: +new_item.value,
              write_on_outgoing: new_item.write_on_outgoing,
              raw_material: new_item.raw_material?.map((el) => {
                delete el.id
                return el.input
              })
            }
          }
        })

        this.currentCompound = new CompoundModel(data.CreateCompound)
        this.$bvModal.show('compound-modal')
        this.$apollo.queries.Compounds.refresh()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .compound-table-card {
    margin-top: 16px;
  }
  .b-status {
    width: 2px;
    height: 32px;
    position: absolute;
    left: 0px;
    background: #bcbcbc;
    border-radius: 0px 1px 1px 0px;

    &.primary {
      background: #00a3ff;
    }
    &.warning {
      background: #e5b509;
    }
  }
  .badge-warning {
    color: #d4a600 !important;
    background-color: #fffae7 !important;
  }
</style>
