<template>
  <section id="add-product">
    <!--    <warning-list-->
    <!--      v-if="show_warning"-->
    <!--      @set_skip_warning="show_warning = false"-->
    <!--      @open_modal_scale="openModalScale"-->
    <!--    />-->
    <div class="title-block">
      <!-- <breadcrumbs
        page_name="add_product"
        style="width: fit-content"
        class="breadcrumbs"
        :title="get_name"
        :barcode_length="barcodes.length"
        @showModalNoBarcode="showModalNoBarcode"
      />
      <div v-if="this.form?.articul" class="line-title"></div>
      <p v-if="this.form?.articul" class="article">
        {{ 'Арт: ' + this.form?.articul }}
      </p> -->
    </div>

    <div class="wrapper__inner-widthlimit">
      <b-row>
        <b-col class="d-flex flex-row justify-content-end actions-panel">
          <b-button
            variant="outline-primary"
            @click="go_back"
          >
            Отмена
          </b-button>
          <b-button
            variant="primary"
            :disabled="!form.name || form.name?.length < 1"
            @click="isNoBarcode"
          >
            Сохранить
          </b-button>
        </b-col>
      </b-row>

      <div class="pos-r">
        <b-tabs active-nav-item-class="text-dark">
          <b-tab title="Основные параметры">
            <b-overlay
              :show="is_loading"
              rounded
              opacity="0.6"
              spinner-variant="primary"
            >
              <div id="product__wrapper">
                <b-row>
                  <b-col class="d-flex flex-row justify-content-start">
                    <div class="product__image">
                      <div class="product__image-inner d-flex cursor">
                        <template v-if="form.photo">
                          <img
                            src="/img/icons/no_photo.svg"
                            alt=""
                          />
                        </template>
                        <template v-else>
                          <img
                            src="/img/icons/no_photo.svg"
                            alt=""
                          />
                        </template>
                      </div>
                      <div class="change cursor">Добавить фото</div>
                    </div>
                    <div class="product__detail">
                      <div class="card pt-0">
                        <b-row>
                          <b-col
                            xl="9"
                            lg="12"
                            class="mb-3"
                          >
                            <label>
                              Наименование товара
                              <span class="color-red">*</span>
                            </label>
                            <b-form-input
                              v-model="form.name"
                              type="text"
                            />
                          </b-col>
                          <b-col
                            xl="3"
                            lg="6"
                            sm="12"
                            class="mb-3"
                          >
                            <label>
                              Единица измерения
                              <span class="color-red">*</span>
                            </label>
                            <i-select
                              v-model="form.measurement.id"
                              :class="{ error: measurementError }"
                            >
                              <i-option
                                v-for="item in measurementList"
                                :key="item.id"
                                :value="item.id"
                              >
                                {{ item.name }}
                              </i-option>
                            </i-select>
                          </b-col>
                          <b-col
                            xl="4"
                            lg="6"
                            sm="12"
                            class="mb-3"
                          >
                            <label class="d-flex justify-content-between">
                              <span>
                                Наименование для ценника
                                <img
                                  v-b-tooltip.hover
                                  :title="print_hint"
                                  src="/img/icons/icon-info.svg"
                                  style="height: 16px; width: 16px"
                                />
                              </span>
                              <span
                                style="color: #888888; font-size: 12px"
                                :class="{
                                  error: form.print_name?.length === 51
                                }"
                                >{{ 51 - (form.print_name?.length || 0) }}</span
                              >
                            </label>
                            <b-form-input
                              v-model="form.print_name"
                              max-length="51"
                              maxlength="51"
                              @input="printNameInput"
                            />
                          </b-col>
                          <b-col
                            xl="4"
                            lg="6"
                            sm="12"
                            class="mb-3"
                          >
                            <label>Вид продукции</label>
                            <b-form-group>
                              <i-select
                                v-model="form.markingType.id"
                                transfer
                              >
                                <i-option-group
                                  label="Немаркированная"
                                  class="option-group-up-border"
                                >
                                  <i-option
                                    v-for="item of unmarkedProducts"
                                    :key="item.key"
                                    :value="item.key"
                                    :label="
                                      item.label === 'Немаркированная' ? item.label : `Немаркированная: ${item.label}`
                                    "
                                  >
                                    <div>{{ item.label }}</div>
                                    <div
                                      v-if="
                                        item.code === 'ALCO' ||
                                        item.code === 'MARKED_ALCO' ||
                                        item.code === 'NOT_MARKED_ALCO'
                                      "
                                      v-b-tooltip.hover.top
                                      title="IOKNJOON"
                                      class="select-option-description"
                                    >
                                      <div v-if="item.code === 'ALCO'">Содержит наклеенную акцизную марку</div>
                                      <div v-if="item.code === 'MARKED_ALCO'">
                                        Признак маркированной продукции для разливных слабоалкогольных напитков
                                      </div>
                                      <div v-if="item.code === 'NOT_MARKED_ALCO'">
                                        Признак слабоалкогольной продукции без требования маркировки
                                      </div>
                                    </div>
                                  </i-option>
                                </i-option-group>

                                <i-option-group
                                  label="Маркированная"
                                  class="option-group-up-border"
                                >
                                  <i-option
                                    v-for="item of markedProducts"
                                    :key="item.key"
                                    :value="item.key"
                                    :label="
                                      item.label === 'Маркированная' ? item.label : `Маркированная: ${item.label}`
                                    "
                                  >
                                    <div>{{ item.label }}</div>
                                    <div
                                      v-if="
                                        item.code === 'ALCO' ||
                                        item.code === 'MARKED_ALCO' ||
                                        item.code === 'NOT_MARKED_ALCO'
                                      "
                                      v-b-tooltip.hover.top
                                      title="KHJBBJHBJB"
                                      class="select-option-description"
                                    >
                                    <div v-if="item.code === 'ALCO'">Содержит наклеенную акцизную марку</div>
                                      <div v-if="item.code === 'MARKED_ALCO'">
                                        Признак маркированной продукции для разливных слабоалкогольных напитков
                                      </div>
                                      <div v-if="item.code === 'NOT_MARKED_ALCO'">
                                        Признак слабоалкогольной продукции без требования маркировки
                                      </div>
                                    </div>
                                  </i-option>
                                </i-option-group>

                                <i-option-group label="Подакцизная">
                                  <i-option
                                    v-for="item of excisableProducts"
                                    :key="item.key"
                                    :value="item.key"
                                    :label="item.label === 'Подакцизная' ? item.label : `Подакцизная: ${item.label}`"
                                  >
                                    <div>{{ item.label }}</div>
                                    <div
                                      v-if="
                                        item.code === 'ALCO' ||
                                        item.code === 'MARKED_ALCO' ||
                                        item.code === 'NOT_MARKED_ALCO'
                                      "
                                      v-b-tooltip.hover.top
                                      title="JHBHBBIJHBHG"
                                      class="select-option-description"
                                    >
                                    <div v-if="item.code === 'ALCO'">Содержит наклеенную акцизную марку</div>
                                      <div v-if="item.code === 'MARKED_ALCO'">
                                        Признак маркированной продукции для разливных слабоалкогольных напитков
                                      </div>
                                      <div v-if="item.code === 'NOT_MARKED_ALCO'">
                                        Признак слабоалкогольной продукции без требования маркировки
                                      </div>
                                    </div>
                                  </i-option>
                                </i-option-group>
                              </i-select>
                            </b-form-group>
                          </b-col>
                          <b-col
                            xl="4"
                            lg="6"
                            sm="12"
                          >
                            <label>Налоговая группа</label>
                            <i-select v-model="form.taxRate.id">
                              <i-option
                                v-for="item in taxRateList"
                                :key="item.id"
                                :value="item.id"
                              >
                                {{ item.name }}
                              </i-option>
                            </i-select>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="card pt-0">
                      <div class="row align-items-end">
                        <b-col
                          col
                          lg="4"
                          md="4"
                        >
                          <label>Страна</label>
                          <i-select
                            v-model="form.country.id"
                            filterable
                          >
                            <i-option
                              v-for="item in countryList"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }} ({{ item.iso }})
                            </i-option>
                          </i-select>
                        </b-col>
                        <b-col
                          col
                          lg="4"
                          md="4"
                        >
                          <e-select
                            v-model="selected_manufacturer"
                            class="manufacturer-select"
                            label="Производитель"
                            selected_type="text"
                            show_bottom_button
                            show_edit
                            position="bottom"
                            search_in_drop
                            placeholder="Выбрать"
                            placeholder-drop="Поиск"
                            :options="manufacturer_list"
                            :serchable="false"
                            @click_edit="clickEditManufacturer"
                            @click_bottom="createManufacturer"
                          >
                            <template #custom_name="{ item }">
                              <div class="manufacturer">
                                <p class="name">{{ item.name }}</p>
                                <div class="inn_kpp">
                                  <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                                  <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                                </div>
                              </div>
                            </template>
                          </e-select>
                          <create-update-manufacturer
                            :manufacturer_list="manufacturer_list"
                            :manufacturer="edit_manufacturer"
                            @close="edit_manufacturer = null"
                            @setManufacturer="setManufacturer"
                            @refetch_entity="$apollo.queries.Entities.refresh()"
                          />
                          <!--                                                                              <manufacturer-select-->
                          <!--                                                                                :manufacturer="form.manufacturer"-->
                          <!--                                                                                @setManufacturer="setManufacturer"-->
                          <!--                                                                              />-->
                        </b-col>
                        <b-col
                          col
                          lg="4"
                          md="4"
                        >
                          <label>Поставщик</label>
                          <i-select
                            v-model="form.supplier.id"
                            filterable
                            clearable
                          >
                            <i-option
                              v-for="item in supplierList"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </i-option>
                          </i-select>
                        </b-col>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="card pt-0 pb-0">
                      <div class="row align-items-end">
                        <b-col
                          lg="3"
                          md="6"
                          sm="6"
                          class="mb-3"
                        >
                          <label>
                            Расположение, группа
                            <span class="color-red">*</span>
                          </label>
                          <div
                            style="padding-right: 30px; word-wrap: break-word; overflow: hidden; white-space: nowrap"
                            class="b-select-group"
                            :class="{ 'b-select-group__error': categoryError }"
                            @click="openModalSelectGroup"
                          >
                            <div class="image-group">
                              <img
                                src="/img/icons/products/pencil-edit.svg"
                                alt="groupIcon"
                              />
                            </div>

                            {{
                              this.form.category?.name
                                ? this.form.category?.name
                                : this.currentCategory.name
                                ? this.currentCategory.name
                                : 'Без категории'
                            }}
                          </div>
                        </b-col>
                        <b-col
                          lg="3"
                          md="6"
                          sm="6"
                          class="mb-3"
                        >
                          <label>Срок годности, Дней</label>
                          <b-form-input
                            v-model="form.expiration_date"
                            type="number"
                          />
                        </b-col>
                        <b-col
                          lg="3"
                          md="6"
                          sm="6"
                          class="mb-3"
                        >
                          <label>Объём, Л</label>
                          <b-form-input
                            v-model="form.volume"
                            type="text"
                            :formatter="formatter_price"
                          />
                        </b-col>
                        <b-col
                          lg="3"
                          md="6"
                          sm="6"
                          class="mb-3"
                        >
                          <label>Тип товарной карточки</label>
                          <i-select v-model="form.productType.id">
                            <i-option
                              v-for="item in productTypeList"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </i-option>
                          </i-select>
                        </b-col>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="card pt-0 pb-0">
                      <div class="row align-items-end">
                        <b-col
                          lg="3"
                          md="4"
                          class="mb-3"
                        >
                          <label>
                            {{
                              form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8'
                                ? 'Закупочная цена, ₽'
                                : 'Себестоимость, ₽'
                            }}
                            <!-- <img
                              id="price-1"
                              src="/img/icons/icon-info.svg"
                              alt=""
                            />
                            <b-tooltip target="price-1">
                              Текст по наведению
                            </b-tooltip> -->
                          </label>
                          <b-form-input
                            v-model="form.purchasePrice"
                            type="text"
                            :disabled="form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8'"
                            :formatter="formatter_price"
                            placeholder="0.00"
                          />
                        </b-col>
                        <b-col
                          lg="3"
                          md="4"
                          class="mb-3"
                        >
                          <label>Наценка, %</label>
                          <b-form-input
                            v-model="form.markup"
                            type="text"
                            placeholder="0.00"
                          />
                        </b-col>
                        <b-col
                          lg="3"
                          md="4"
                          class="mb-3"
                        >
                          <label>
                            Розничная цена, ₽
                            <!-- <img
                              id="price-2"
                              src="/img/icons/icon-info.svg"
                              alt=""
                            />
                            <b-tooltip target="price-2">
                              Текст по наведению
                            </b-tooltip> -->
                          </label>
                          <b-form-input
                            v-model="form.retailPrice"
                            type="text"
                            :formatter="formatter_price"
                            placeholder="0.00"
                          />
                        </b-col>
                        <b-col
                          lg="3"
                          md="4"
                          class="mb-3"
                        >
                          <label> Округление </label>
                          <i-select v-model="form.round_to">
                            <i-option
                              v-for="item in round"
                              :key="item.value"
                              :value="item.value"
                            >
                              {{ item.label }}
                            </i-option>
                          </i-select>
                        </b-col>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-tabs
                v-model="tabIndex"
                fill
                active-nav-item-class="text-dark font-weight-bold"
              >
                <b-tab title="Штрих-коды">
                  <div id="tab-barcode">
                    <b-row class="b-barcode-header">
                      <b-col md="6">
                        <div class="b-barcode-scan"></div>
                      </b-col>
                      <b-col>
                        <div class="b-barcode-btns d-flex justify-content-end">
                          <b-button
                            v-b-modal.modal-barcode
                            variant="primary"
                          >
                            <img
                              src="/img/icons/btn-plus.svg"
                              class="mr"
                              alt=""
                            />
                            Добавить штрих-код
                          </b-button>
                          <b-dropdown
                            no-caret
                            variant="none"
                            class="ml-3"
                          >
                            <template #button-content>
                              <div class="btn-more">
                                <img
                                  src="/img/icons/more.svg"
                                  alt=""
                                />
                              </div>
                            </template>
                            <b-dropdown-item @click="showPrefixModal">
                              Изменить префикс весового штрих-кода</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </b-col>
                    </b-row>
                    <modal-barcode
                      ref="modal_barcode"
                      :barcodes="barcodes"
                      :ean="ean"
                      @add-barcode="addBarcode"
                    />
                    <b-card
                      no-body
                      class="mt-3 p-0"
                    >
                      <b-table
                        ref="table_barcodes"
                        :fields="fields"
                        :items="barcodes.filter((el) => el.barcode)"
                        :busy="tableIsBusy"
                        empty-filtered-text="Нет штрих-кодов"
                        show-empty
                        class="table-barcodes"
                      >
                        <template #table-busy>
                          <div class="text-center">
                            <b-spinner
                              variant="primary"
                              label="Загрузка..."
                            />
                            <div><strong>Загрузка...</strong></div>
                          </div>
                        </template>
                        <template #cell(barcode)="data">
                          {{ data.value.barcode }}
                        </template>

                        <template #cell(value)="data">
                          <template v-if="isEditPackageBarcode !== data.item.barcode?.id">
                            <div
                              class="d-flex align-items-center cursor"
                              @click="toggleEditPackageBarcode(data.value, data.item.barcode?.id)"
                            >
                              {{ data.value }}
                              <img
                                src="/img/icons/icon_pencil_edit.svg"
                                class="ml-2"
                                alt="Редактировать количество"
                              />
                            </div>
                          </template>
                          <template v-else>
                            <b-form-input
                              v-model="editPackageBarcode"
                              type="number"
                              class="edit-package-barcode"
                              @keyup.enter="handlerUpdatePackageBarcode(data)"
                            />
                          </template>
                        </template>

                        <template #empty="data">
                          <p class="center mt-3">
                            {{ data.emptyFilteredText }}
                          </p>
                        </template>
                        <template #cell(tools)="data">
                          <button
                            class="btn icon"
                            @click="removeBarcode(data)"
                          >
                            <img
                              src="/img/icons/trash.svg"
                              alt=""
                              width="16"
                              height="16"
                            />
                          </button>
                        </template>
                      </b-table>
                    </b-card>
                  </div>
                </b-tab>
                <b-tab title="Внешний код контрагента">
                  <external-id-modal
                    :product_id="product_id"
                    :supplier_list="supplierList"
                    :edit_id="extId"
                    @created="refetch"
                    @add_external="addExternal"
                    @close-edit="closeEdit"
                  />
                  <div id="tab-barcode">
                    <b-row class="b-barcode-header">
                      <b-col>
                        <div class="d-flex justify-content-between align-middle">
                          <div class="ext-caption">
                            <img
                              src="/img/icons/info_blue.svg"
                              class="mr-2"
                            />
                            <span class="text-dark"
                              >Добавляйте внешние коды товаров от поставщиков, чтобы система верно сопоставляла позиции
                              при импорте
                            </span>
                          </div>
                          <b-button
                            v-b-modal.external-id-modal
                            variant="primary"
                          >
                            <img
                              src="/img/icons/btn-plus.svg"
                              class="mr"
                              alt=""
                            />
                            Добавить
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-card
                      no-body
                      class="mt-3 p-0"
                    >
                      <b-table
                        ref="table_barcodes"
                        :fields="ext_id_fields"
                        :items="form.productExternalId"
                        :busy="externalBusy"
                        empty-filtered-text="Нет внешних кодов"
                        show-empty
                        class="table-barcodes"
                      >
                        <template #table-busy>
                          <div class="text-center">
                            <b-spinner
                              variant="primary"
                              label="Загрузка..."
                            />
                            <div><strong>Загрузка...</strong></div>
                          </div>
                        </template>

                        <template #cell(supplier)="{ item }">
                          {{ supplierList.find((el) => el.id === item.supplier)?.name ?? '-' }}
                        </template>

                        <template #cell(action)="{ item }">
                          <b-dropdown
                            no-caret
                            variant="none"
                          >
                            <template #button-content>
                              <img src="/img/icons/more.svg" />
                            </template>
                            <b-dropdown-item @click="editExt(item)"> Редактировать</b-dropdown-item>
                            <b-dropdown-item @click="removeExt(item.id)"> Удалить</b-dropdown-item>
                          </b-dropdown>
                        </template>

                        <template #empty="data">
                          <p class="center mt-3">
                            {{ data.emptyFilteredText }}
                          </p>
                        </template>
                      </b-table>
                    </b-card>
                  </div>
                </b-tab>
                <b-tab title="Упаковки">
                  <package-modal
                    :ean="ean"
                    :barcodes="barcodes"
                    :pkg="pckg"
                    @clearPckg="clearPckg"
                    @add_package="addPackage"
                  />
                  <div id="tab-barcode">
                    <b-row class="b-barcode-header">
                      <b-col>
                        <div class="d-flex justify-content-end align-middle">
                          <b-button
                            variant="primary"
                            @click="openPckg"
                          >
                            <img
                              src="/img/icons/btn-plus.svg"
                              class="mr"
                              alt=""
                            />
                            Добавить упаковку
                          </b-button>
                        </div>
                      </b-col>
                    </b-row>
                    <b-card
                      no-body
                      class="mt-3 p-0"
                    >
                      <package-table
                        :packages="barcodes"
                        @edit_package="editPckg"
                        @refresh="refetch"
                        @remove_package="removeBarcode"
                      />
                    </b-card>
                  </div>
                </b-tab>
                <b-tab title="Весы">
                  <scale-tab
                    :barcodes="barcodes"
                    :measurement="form.measurement"
                    style="margin-top: 22px"
                    :product="form"
                    :ean="ean"
                    :product_id="product_id"
                    @refetch="refetch"
                    @add-barcode="addBarcode"
                  />
                </b-tab>
              </b-tabs>
            </b-overlay>
          </b-tab>
          <b-tab title="Калькуляция">
            <compound-table
              :product="product_id"
              @handlerProduct="handlerSaveProduct"
            />
          </b-tab>
          <b-tab
            v-if="form.ext_system?.mercury?.length || form.ext_system?.other?.length || form.ext_system?.egais?.length"
            title="Внешние системы"
          >
            <div class="mt-3">
              <mercury-ext-card
                :products="form.ext_system?.mercury ?? []"
                :product="product_id"
                @refresh="refetch"
              />
              <crptech-ext-card
                :product="product_id"
                :products="form.ext_system?.other ?? []"
                @refresh="refetch"
              />
              <egais-ext-card
                :product="product_id"
                :products="form.ext_system?.egais ?? []"
                @refresh="refetch"
              />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <no-barcode
      ref="modal_no_barcode"
      @showAddBarcode="showAddBarcode"
      @handlerSaveProduct="handlerSaveProduct"
    />
    <modal-select-group
      ref="modal_select_group"
      :set_category="setCategory"
      :category="form.category"
      :modal_show="modal_select_group"
    />
  </section>
</template>

<script>
  import NoBarcode from '../modal/NoBarcode.vue'
  import { mapActions, mapGetters } from 'vuex'
  import ModalBarcode from '../modal/ModalBarcode.vue'
  import ModalSelectGroup from '../modal/ModalSelectGroup.vue'
  import ExternalIdModal from '@/views/products/modal/ExternalIdModal'
  import CompoundTable from '@/views/manufacture/components/CompoundTable'
  import MercuryExtCard from '@/views/products/components/add/ext-systems/MercuryExtCard'
  import ManufacturerSelect from '@/views/products/components/ManufacturerSelect'
  import { ProductModel } from '@/models/product.model'
  import { CountryModel } from '@/models/country.model'
  import { MarkingTypeModel } from '@/models/marking-type.model'
  import { MeasurementModel } from '@/models/measurement.model'
  import { ProductTypeModel } from '@/models/product-type.model'
  import { TaxRateModel } from '@/models/tax-rate.model'
  import PackageTable from '@/views/products/components/add/PackageTable'
  import PackageModal from '@/views/products/modal/PackageModal'
  import ScaleTab from '@/views/products/components/ScaleTab.vue'
  import CrptechExtCard from '@/views/products/components/add/ext-systems/CrptechExtCard.vue'
  import EgaisExtCard from '@/views/products/components/add/ext-systems/EgaisExtCard.vue'
  import WarningList from '@/views/products/modal/WarningList'
  import { EntityModel } from '@/models/entity.model'
  import CreateUpdateManufacturer from '@/views/products/modal/CreateUpdateManufacturer'

  export default {
    components: {
      WarningList,
      CreateUpdateManufacturer,
      EgaisExtCard,
      CrptechExtCard,
      PackageModal,
      PackageTable,
      ManufacturerSelect,
      MercuryExtCard,
      ExternalIdModal,
      ModalBarcode,
      ModalSelectGroup,
      CompoundTable,
      ScaleTab,
      NoBarcode
    },

    apollo: {
      ProductDefault: {
        query: require('../gql/getProductDefault.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (!this.form.id) this.setDefaultSettings(data.ProductDefault)
          this.defaults = data.ProductDefault
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Suppliers: {
        query: require('../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplierList = [
            // { id: null, name: 'Все' },
            ...(data.Suppliers ?? [])
          ]
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Measurements: {
        query: require('../gql/getMeasurements.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.measurementList = data.Measurements
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      TaxRates: {
        query: require('../gql/getTaxRates.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.taxRateList = data.TaxRates
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Entities: {
        query: require('../gql/getEntities.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.manufacturer_list = data.Entities?.map((el) => new EntityModel(el)) || []
        }
      },

      MarkingTypes: {
        query: require('../gql/getMarkingTypes.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          const markingTypeList = data?.MarkingTypes ?? []
          markingTypeList.forEach((el) => {
            if (el.isExcisable === true) {
              const item = { key: el.id, label: el.name, code: el.code }
              this.excisableProducts.push(item)
            } else if (el.isMarked === true && el.isExcisable === false) {
              const item = { key: el.id, label: el.name, code: el.code }
              this.markedProducts.push(item)
            } else {
              const item = { key: el.id, label: el.name, code: el.code }
              this.unmarkedProducts.push(item)
            }
          })
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      ProductTypes: {
        query: require('../gql/getProductTypes.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.productTypeList = data.ProductTypes?.filter((el) => el.name !== 'Оборудование') ?? []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Countries: {
        query: require('../gql/getCountries.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.countryList = data.Countries
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Product: {
        query: require('../gql/getProduct.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            id: this.product_id,
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          const product = data?.Product
          if (product) this.setCurrentProductInfo(product)
          this.is_loading = false
        },
        error(error) {
          console.error(`Ошибка запроса: ${error}`)
          this.is_loading = false
        },
        skip() {
          return !this.$route.params.isEdit && !this.$route.params.copyProduct
        }
      }
    },

    data: () => ({
      tabIndex: 0,
      show_warning: false,
      edit_manufacturer: null,
      selected_manufacturer: [],
      manufacturer_list: [],
      categoryError: false,
      measurementError: false,
      categories: {},
      is_loading: true,
      product_id: '',
      defaults: null,
      countryList: [],
      unmarkedProducts: [],
      markedProducts: [],
      excisableProducts: [],
      measurementList: [],
      productTypeList: [],
      taxRateList: [],
      supplierList: [],
      print_hint:
        'Текст, который будет отображаться\n' +
        'на ценнике при печати.\n' +
        'Если поле не заполнено - будет\n' +
        'отображаться основное\n' +
        'наименование ',
      form: new ProductModel(),
      tableIsBusy: false,
      externalBusy: false,
      extId: null,
      pckg: null,
      fields: [
        {
          key: 'barcode',
          label: 'Штрихкод',
          class: 'w-40'
        },
        {
          key: 'tools',
          label: 'Действия',
          class: 'w-10'
        }
      ],
      ext_id_fields: [
        {
          key: 'supplier',
          label: 'Контрагент'
        },
        {
          key: 'external_id',
          label: 'Код товара контрагента'
        },
        {
          key: 'ext_name',
          label: 'Наименование товара поставщика'
        },
        {
          key: 'ext_quant',
          label: 'Упаковка'
        },
        {
          key: 'action',
          label: 'Действия'
        }
      ],
      externals: [],
      barcodes: [],
      modal_select_group: false,
      isEditPackageBarcode: false,
      editPackageBarcode: null,
      ean: 'piece',
      round: [
        {
          label: 'До рубля',
          value: 'rubles'
        },
        {
          label: 'До 50 коп.',
          value: 'half'
        },
        {
          label: 'Не округлять',
          value: 'none'
        }
      ]
    }),

    computed: {
      ...mapGetters({
        currentCategory: 'products/getCurrentCategory',
        currentProductId: 'products/getCurrentProductId',
        currentBranch: 'settings/getCurrentBranch',
        incomingSpecs: 'operprocess/getIncomingSpecs'
      }),
      get_name() {
        return this.$route.params.name || this.form?.name || ''
      }
    },

    watch: {
      selected_manufacturer() {
        this.form.manufacturer = this.selected_manufacturer?.[0] ?? {}
      },
      'form.measurement.id'(newVal) {
        this.measurementError = false
        this.measurementList.forEach(({ id, name }) => {
          if (newVal !== id) return
          if (name === 'КГ.') return (this.ean = 'weight')
          return (this.ean = 'piece')
        })
        if (this.product_id && !this.is_loading) {
          this.simpleUpdateProduct()
        }
        this.showHideWarning()
      }
    },

    beforeMount() {
      this.setBreadcrumbs({
        title: this.get_name,
        art: this.form?.articul,
        is_go_back: true
      })
    },

    mounted() {
      this.showHideWarning()
      if (!this.form.name) {
        if (this.$route.query.product) {
          this.form = new ProductModel({
            ...this.$route.query.product,
            ext_system: { other: this.$route.query.product?.ext_id },
            category: this.category
          })
          this.form.productExternalId = this.$route.query.product?.ext_id ?? []
        }
      }
      if (!this.form.name) {
        if (this.$route.params) {
          this.form = new ProductModel({
            ...this.$route.params,
            ext_system: { other: this.$route.params?.ext_id ?? [] },
            category: this.category
          })
          this.form.productExternalId = this.$route.params?.ext_id ?? []
        }
      }
    },

    destroyed() {
      this.setBreadcrumbs({})
    },

    beforeDestroy() {
      this.setBreadcrumbs({})
    },

    async created() {
      if (this.$route.query.barcode) this.addBarcode(this.$route.query.barcode)
      if (this.$route.query.product) {
        if (this.$route.query.product.category) {
          const { data } = await this.$apollo.query({
            query: require('../gql/getCategory.gql'),
            variables: {
              id: this.$route.query?.product?.category,
              branch: this.currentBranch.id
            }
          })
          this.category = { id: data.Category.id, name: data.Category.name }
        }
        this.form = new ProductModel({
          ...this.$route.query.product,
          ext_system: { other: this.$route.query.product?.ext_id },
          category: this.category
        })
        this.form.productExternalId = this.$route.query.product?.ext_id ?? []
      }

      if (this.$route.meta.isEdit) {
        this.setCurrentProdctId(this.$route.params.id)
        this.product_id = this.$route.params.id
        this.$apollo.queries.Product.start()
      } else {
        this.is_loading = false
      }
      if (this.$route.params.copyProduct) {
        this.product_id = this.$route.params.id
        this.$apollo.queries.Product.start()
      }

      if (!this.form.category.id) this.form.category.id = this.currentCategory.id
    },

    methods: {
      ...mapActions({
        setCurrentProdctId: 'products/setCurrentProductId',
        setBreadcrumbs: 'breadcrumbs/set_current',
        setSpec: 'operprocess/setIncomingSpecificationById'
      }),
      createManufacturer() {
        this.edit_manufacturer = null
        this.$bvModal.show('create-update-manufacturer')
      },
      clickEditManufacturer(item) {
        this.edit_manufacturer = item
        this.$bvModal.show('create-update-manufacturer')
      },
      addExternal(val) {
        if (Array.isArray(this.form.productExternalId)) {
          if (this.form.productExternalId.find((obj) => obj.id === val.id)) {
            this.form.productExternalId = this.form.productExternalId.map((obj) => {
              if (obj.id === val.id) {
                return val
              }
              return obj
            })
            this.$bvModal.hide('external-id-modal')
            this.extId = null
            return
          }
          this.form.productExternalId.push(val)
        } else {
          this.form.productExternalId = [val]
        }
        this.$bvModal.hide('external-id-modal')
      },
      ...mapActions({
        setCurrentProdctId: 'products/setCurrentProductId',
        setBreadcrumbs: 'breadcrumbs/set_current'
      }),
      showHideWarning() {
        if (
          this.form?.measurement?.id === '369fbe35-9ace-4b21-8308-489eddf83413' &&
          !localStorage.getItem('hideWarningScale') &&
          !this.form.plu?.[0]
        ) {
          this.show_warning = true
        }
        if (localStorage.getItem('hideWarningScale')) {
          this.show_warning = false
        }
      },
      openModalScale() {
        this.tabIndex = 3
        this.$bvModal.show('add-scales-modal')
        this.show_warning = false
      },
      openPckg() {
        this.pckg = {
          id: null,
          barcode: { barcode: '' },
          length: '',
          width: '',
          height: '',
          value: ''
        }
        this.$bvModal.show('package-modal')
      },
      setManufacturer(manufacturer) {
        this.form.manufacturer = manufacturer
        this.selected_manufacturer = [manufacturer]
      },
      openManufacturerSelect() {},
      formatter_price(value) {
        return value.replace(/^\.|[^\d.]|\.(?=.*\.)|^0+(?=\d)/g, '')
      },

      setDefaultSettings(product) {
        this.form.country = new CountryModel(product.country)
        this.form.markingType = new MarkingTypeModel(product.markingType)
        this.form.measurement = new MeasurementModel(product.measurement)
        this.form.productType = new ProductTypeModel(product.productType)
        this.form.taxRate = new TaxRateModel(product.taxRate)
        // this.form = R.mergeRight(this.form, product)
      },

      toggleEditPackageBarcode(val, id) {
        this.editPackageBarcode = val
        this.isEditPackageBarcode = this.isEditPackageBarcode === id ? false : id
      },

      refetch() {
        this.$apollo.queries.Product.refetch()
      },

      showAddBarcode() {
        this.$refs.modal_barcode.showModal()
      },

      editExt(item) {
        this.extId = item
        this.$bvModal.show('external-id-modal')
      },

      closeEdit() {
        this.extId = null
      },

      removeExt(id) {
        if (!this.product_id) {
          this.form.productExternalId = this.form.productExternalId.filter((obj) => obj.id !== id)
          return
        }
        this.$apollo
          .mutate({
            mutation: require('../gql/RemoveExternalId.graphql'),
            variables: {
              id
            }
          })
          .then(() => this.$apollo.queries.Product.refetch())
      },
      setCategory(category) {
        if (category) this.form.category = category
      },

      handlerUpdatePackageBarcode({ index, item }) {
        this.$apollo
          .mutate({
            mutation: require('../gql/UpdatePackage.gql'),
            variables: {
              id: item.id,
              value: Number(this.editPackageBarcode)
            }
          })
          .then(({ data }) => {
            this.barcodes[index].value = data.UpdatePackage.value
            this.editPackageBarcode = null
            this.isEditPackageBarcode = false
            // this.barcodes.push({ ...item })
          })
          .catch(() => this.$noty.show(`При изменении штрихкода что-то пошло не так. Попробуйте еще раз`))
      },

      async addPackage(pckg) {
        try {
          if (!this.$route.meta.isEdit) {
            const findEl = this.barcodes.find((el, index) => {
              if (el?.barcode?.barcode === pckg?.barcode?.barcode) return { el, index }
            })
            if (findEl) {
              this.$bvModal.hide('package-modal')
              return (this.barcodes[findEl.index] = pckg)
            } else {
              this.$bvModal.hide('package-modal')
              return this.barcodes.push({ ...pckg })
            }
          }
          if (!pckg.id) {
            let { data } = await this.$apollo.mutate({
              mutation: require('../gql/CreatePackage.gql'),
              variables: {
                product: this.product_id,
                barcode: pckg?.barcode ?? null,
                value: +pckg.value,
                length: +pckg.length,
                width: +pckg.width,
                height: +pckg.height
              }
            })
            if (data) {
              this.$bvModal.hide('package-modal')
              this.refetch()
            }
            this.pckg = null
          } else {
            let { data } = await this.$apollo.mutate({
              mutation: require('../gql/UpdatePackage.gql'),
              variables: {
                id: pckg.id,
                product: this.product_id,
                barcode: pckg?.barcode ?? null,
                value: +pckg.value,
                length: +pckg.length,
                width: +pckg.width,
                height: +pckg.height
              }
            })
            if (data) {
              this.$bvModal.hide('package-modal')
              this.refetch()
            }
          }
          this.pckg = null
        } catch (e) {
          if (e?.graphQLErrors?.[0]?.message === 'BARCODE_ALREADY_EXISTS') {
            this.$noty.error('Штрихкод уже существует')
          } else this.$noty.error(e?.graphQLErrors?.[0]?.message?.extensions?.ru ?? 'Ошибка')
        }
      },

      clearPckg() {
        this.pckg = null
      },

      editPckg(pckg) {
        this.pckg = pckg
        this.$bvModal.show('package-modal')
      },

      isNoBarcode() {
        if (!localStorage.getItem('no-barcode') && this.barcodes.length < 1) {
          this.showModalNoBarcode()
        } else {
          this.handlerSaveProduct()
        }
      },

      async simpleUpdateProduct() {
        await this.$apollo.mutate({
          mutation: require('../gql/SimpleUpdateProduct.graphql'),
          variables: {
            input: {
              id: this.product_id,
              measurement: this.form.measurement.id
            }
          }
        })
      },

      handlerSaveProduct() {
        const f = this.form
        if (!f.measurement.id) {
          this.measurementError = true
          return this.$noty.error('Необходимо выбрать еденицу измерения')
        }
        if (!(f.category?.id || this.currentCategory?.id) || f.category?.id === null) {
          this.categoryError = true
          return this.$noty.error('Не выбрана группа товара')
        }
        this.toggleLoading()
        let query = this.$route.meta.isEdit ? 'updateProduct.gql' : 'createProduct.gql'
        if (f.hide && this.$route.query.spec_id) query = 'replaceProduct.graphql'
        const input = {
          id: this.$route.meta.isEdit ? f.id : '',
          name: f.name,
          articul: f.articul,
          print_name: f.print_name,
          branch: this.currentBranch.id,
          category: f.category?.id || this.currentCategory.id,
          measurement: f.measurement.id,
          supplier: f?.supplier?.id,
          expiration_date: Number(f.expiration_date),
          volume: f.volume ? Number(f.volume) : 0,
          purchasePrice: f.purchasePrice ? Number(f.purchasePrice) : 0,
          retailPrice: f.retailPrice ? Number(f.retailPrice) : 0,
          markup: (f.markup ? Number(f.markup) : 0) / 100,
          productType: f.productType.id ?? this.defaults?.productType?.id,
          markingType: f.markingType.id ?? this.defaults?.markingType?.id,
          taxRate: f.taxRate.id ?? this.defaults?.taxRate?.id,
          country: f.country.id,
          package: this.barcodes,
          manufacturer: f.manufacturer?.id,
          external_id: f?.ext_system?.other ?? null,
          round_to: f?.round_to ?? 'none'
        }
        //для добавление товара при созании связи если f.hide && this.$route.query.spec_id
        this.$apollo
          .mutate({
            mutation: require(`../gql/${query}`),
            variables: {
              input:
                f.hide && this.$route.query.spec_id
                  ? {
                      spec_id: this.$route.query.spec_id,
                      new_product: input
                    }
                  : input
            }
          })
          .then((data) => {
            if (this.$route.meta.isEdit) {
              this.$noty.show(`Изменения сохранены`)
            } else {
              this.$noty.show(`Товар добавлен`)
              this.is_loading = true
            }
            if (data.data?.ReplaceProduct) {
              this.setSpec(data.data?.ReplaceProduct)
            } else {
              const spec = this.incomingSpecs?.find((el) => el.product?.id === this.form.id)
              if (spec) {
                spec.product = new ProductModel({
                  ...spec.product,
                  ...this.form
                })
              }
            }

            this.$router.back()
          })
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При создании товара что-то пошло не так. Попробуйте еще раз`)
          })
          .finally(() => {
            this.toggleLoading(false)
          })
      },

      addBarcode(barcode_data) {
        const barcode = barcode_data?.barcode_item?.barcode?.barcode || barcode_data
        const value = Number(barcode_data.barcode_item?.value || 1)
        const item = {
          barcode: {
            barcode: barcode
          },
          value: Number(value)
        }

        if (!this.$route.meta.isEdit) return this.barcodes.push({ ...item })

        this.$apollo
          .mutate({
            mutation: require('../gql/CreatePackage.gql'),
            variables: {
              product: this.product_id,
              barcode: {
                barcode: barcode
              },
              value: value
            }
          })
          .then(({ data }) => this.barcodes.push(data.CreatePackage))
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При сохранении штрихкода что-то пошло не так. Попробуйте еще раз`)
          })
      },

      removeBarcode({ index, item }) {
        if (!this.$route.meta.isEdit) return this.barcodes.splice(index, 1)
        this.$apollo
          .mutate({
            mutation: require('../gql/RemovePackage.gql'),
            variables: {
              id: item.id
            }
          })
          .then(() => this.barcodes.splice(index, 1))
          .catch((e) => {
            console.error('error: ', e)
            this.$noty.show(`При удалении штрихкода что-то пошло не так. Попробуйте еще раз`)
          })
      },

      setCurrentProductInfo(product) {
        if (this.$route.params.copyProduct) {
          delete product.id
          delete product.package
          delete product.plu
          delete product.articul
          product.name = product.name + ' (копия)'

          this.form = new ProductModel(product)
          if (this.form.manufacturer.id) {
            this.selected_manufacturer = [this.form.manufacturer]
          }
        } else {
          this.form = new ProductModel(product)
          if (this.form.manufacturer.id) {
            this.selected_manufacturer = [this.form.manufacturer]
          }
          this.setBreadcrumbs({
            title: this.get_name,
            art: this.form?.articul,
            is_go_back: true
          })
          if (this.form.package) {
            this.barcodes = product.package
          }
        }

        this.toggleLoading()
      },

      toggleLoading(status) {
        if (status) return (this.is_loading = status)

        this.is_loading = !this.is_loading
      },

      change() {},

      remove() {},

      go_back() {
        // this.is_loading = true
        // this.$router.push({ name: 'products.list' })
        this.$router.back()
      },

      showModalNoBarcode() {
        this.$refs.modal_no_barcode.showModal()
      },

      openModalSelectGroup() {
        this.$refs.modal_select_group.showModal()
      },
      printNameInput(value) {
        this.form.print_name = value.slice(0, 51)
        this.$forceUpdate()
      },
      showPrefixModal() {
        this.$bvModal.show('barcode-prefix-modal')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .select-option-description {
    width: 250px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #888888;
    white-space: wrap;
    overflow: hidden;
  }
  :deep .ivu-select-group-title {
    color: var(--text-placeholder-gray-300, #bcbcbc);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    // line-height: 24px;
  }
  .select-group-label {
    // :deep .ivu-select-group-title {
    //   color: red;
    // }
    color: var(--text-placeholder-gray-300, #bcbcbc);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .title-block {
    display: flex;
    align-items: center;

    .article {
      margin: 0;
      padding: 0;
      margin-left: 11px;
      height: 24px;
      font-family: 'Gilroy-Regular';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }

    .line-title {
      width: 1px;
      height: 12px;
      background-color: #bcbcbc;
      border-radius: 16px;
    }
  }

  #add-product {
    .actions-panel {
      gap: 10px 16px;
      flex-wrap: wrap;
    }

    .tab-color {
      color: black !important;
      font-weight: 500;
    }

    .ext-caption {
      display: flex;
      padding: 0px 16px;
      height: 32px;
      align-items: center;
      background: #e6f0ff;
      border: 1px solid #b9d6ff;
      border-radius: 4px;
    }

    .tabs {
      margin-top: 28px;

      ::v-deep .nav-tabs {
        li.nav-item {
          width: auto;
        }
      }
    }

    .breadcrumbs {
      margin: 0;
      align-items: center;
    }

    .b-select-group {
      position: relative;
      border: 1px solid #bcbcbc;
      border-radius: 2px;
      height: 32px;
      font-size: 14px;
      padding: 5px 10px;
      font-weight: 400;
      line-height: 1.5;
      cursor: pointer;
      &__error {
        border-color: #e53835;
      }

      .image-group {
        position: absolute;
        right: 0;
        top: 50%;
        width: 30px;
        height: 100%;
        background-color: #fff;
        z-index: 2;

        img {
          width: 14px;
          height: 14px;
          position: absolute;
          top: 50%;
          right: 7px;
          transform: translate(0%, -50%);
        }

        transform: translate(0%, -50%);
      }
    }

    input {
      &.edit-package-barcode {
        width: 100px;
      }
    }
  }

  #product__wrapper {
    .row {
      margin-top: 16px;
    }

    .product {
      &__image {
        width: 168px;
        margin-right: 20px;
        text-align: center;

        &-inner {
          width: 100%;
          height: 168px;
          background: #f3f3f3;
          border-radius: 6px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
        }

        .change {
          font-size: 16px;
          line-height: 24px;
          color: #888888;
          text-decoration: underline;
          margin-top: 10px;
        }

        .ivu-upload {
          &-select {
            width: 100%;
          }

          &-drag {
            border: none;
            background: none;
          }
        }
      }

      &__detail {
        flex: 1;
      }
    }

    .card {
      background: #ffffff;
      border: 0.5px solid #e1e1e1;
      border-radius: 3px;
      padding: 22px;
      margin-bottom: 12px;

      label {
        display: flex;
        align-items: center;

        img {
          margin-left: 5px;
        }
      }

      .row.mb-4 {
        margin-bottom: 20px !important;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .tabs-bottom {
      margin-top: 28px;

      .tab-content {
        padding-top: 22px;
      }
    }

    .small-width {
      .ivu-select {
        width: 130px;
      }
    }
  }

  #tab-barcode {
    margin-top: 22px;
    margin-bottom: 50px;

    .b-barcode {
      &-btns {
      }

      &-table {
        margin-top: -1px;
        border: 0;

        ::v-deep tbody tr:last-child {
          border-bottom: 0;
        }
      }
    }

    .table-panel {
      padding: 10px;
      box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
      position: relative;

      .btn-white {
        font-size: 16px;
      }
    }
  }
  .manufacturer-select {
    :deep .label {
      margin-left: 0;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .manufacturer {
    width: 100%;
    display: flex;
    flex-direction: column;
    .name {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }
    .inn_kpp {
      display: flex;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
      .kpp {
        margin-left: 12px;
      }
    }
    p {
      display: flex;
      margin: 0;
    }
  }
  .option-group-up-border {
    border-top: 1px solid #d9d9d9;
  }
  :deep .ms__dropdown-item {
    &.active {
      .name {
        color: #00a3ff !important;
      }
    }
  }
</style>
