var render = function render(){
  var _vm$form$name, _vm$form$print_name, _vm$form$print_name2, _this$form$category, _this$form$category2, _vm$form$ext_system, _vm$form$ext_system$m, _vm$form$ext_system2, _vm$form$ext_system2$, _vm$form$ext_system3, _vm$form$ext_system3$, _vm$form$ext_system$m2, _vm$form$ext_system4, _vm$form$ext_system$o, _vm$form$ext_system5, _vm$form$ext_system$e, _vm$form$ext_system6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "add-product"
    }
  }, [_c('div', {
    staticClass: "title-block"
  }), _c('div', {
    staticClass: "wrapper__inner-widthlimit"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-end actions-panel"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.go_back
    }
  }, [_vm._v(" Отмена ")]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": !_vm.form.name || ((_vm$form$name = _vm.form.name) === null || _vm$form$name === void 0 ? void 0 : _vm$form$name.length) < 1
    },
    on: {
      "click": _vm.isNoBarcode
    }
  }, [_vm._v(" Сохранить ")])], 1)], 1), _c('div', {
    staticClass: "pos-r"
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "text-dark"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Основные параметры"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.is_loading,
      "rounded": "",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    attrs: {
      "id": "product__wrapper"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-start"
  }, [_c('div', {
    staticClass: "product__image"
  }, [_c('div', {
    staticClass: "product__image-inner d-flex cursor"
  }, [_vm.form.photo ? [_c('img', {
    attrs: {
      "src": "/img/icons/no_photo.svg",
      "alt": ""
    }
  })] : [_c('img', {
    attrs: {
      "src": "/img/icons/no_photo.svg",
      "alt": ""
    }
  })]], 2), _c('div', {
    staticClass: "change cursor"
  }, [_vm._v("Добавить фото")])]), _c('div', {
    staticClass: "product__detail"
  }, [_c('div', {
    staticClass: "card pt-0"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "9",
      "lg": "12"
    }
  }, [_c('label', [_vm._v(" Наименование товара "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('b-form-input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "3",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v(" Единица измерения "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('i-select', {
    class: {
      error: _vm.measurementError
    },
    model: {
      value: _vm.form.measurement.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.measurement, "id", $$v);
      },
      expression: "form.measurement.id"
    }
  }, _vm._l(_vm.measurementList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "4",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', {
    staticClass: "d-flex justify-content-between"
  }, [_c('span', [_vm._v(" Наименование для ценника "), _c('img', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "title": _vm.print_hint,
      "src": "/img/icons/icon-info.svg"
    }
  })]), _c('span', {
    class: {
      error: ((_vm$form$print_name = _vm.form.print_name) === null || _vm$form$print_name === void 0 ? void 0 : _vm$form$print_name.length) === 51
    },
    staticStyle: {
      "color": "#888888",
      "font-size": "12px"
    }
  }, [_vm._v(_vm._s(51 - (((_vm$form$print_name2 = _vm.form.print_name) === null || _vm$form$print_name2 === void 0 ? void 0 : _vm$form$print_name2.length) || 0)))])]), _c('b-form-input', {
    attrs: {
      "max-length": "51",
      "maxlength": "51"
    },
    on: {
      "input": _vm.printNameInput
    },
    model: {
      value: _vm.form.print_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "print_name", $$v);
      },
      expression: "form.print_name"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "xl": "4",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Вид продукции")]), _c('b-form-group', [_c('i-select', {
    attrs: {
      "transfer": ""
    },
    model: {
      value: _vm.form.markingType.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.markingType, "id", $$v);
      },
      expression: "form.markingType.id"
    }
  }, [_c('i-option-group', {
    staticClass: "option-group-up-border",
    attrs: {
      "label": "Немаркированная"
    }
  }, _vm._l(_vm.unmarkedProducts, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key,
        "label": item.label === 'Немаркированная' ? item.label : `Немаркированная: ${item.label}`
      }
    }, [_c('div', [_vm._v(_vm._s(item.label))]), item.code === 'ALCO' || item.code === 'MARKED_ALCO' || item.code === 'NOT_MARKED_ALCO' ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "select-option-description",
      attrs: {
        "title": "IOKNJOON"
      }
    }, [item.code === 'ALCO' ? _c('div', [_vm._v("Содержит наклеенную акцизную марку")]) : _vm._e(), item.code === 'MARKED_ALCO' ? _c('div', [_vm._v(" Признак маркированной продукции для разливных слабоалкогольных напитков ")]) : _vm._e(), item.code === 'NOT_MARKED_ALCO' ? _c('div', [_vm._v(" Признак слабоалкогольной продукции без требования маркировки ")]) : _vm._e()]) : _vm._e()]);
  }), 1), _c('i-option-group', {
    staticClass: "option-group-up-border",
    attrs: {
      "label": "Маркированная"
    }
  }, _vm._l(_vm.markedProducts, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key,
        "label": item.label === 'Маркированная' ? item.label : `Маркированная: ${item.label}`
      }
    }, [_c('div', [_vm._v(_vm._s(item.label))]), item.code === 'ALCO' || item.code === 'MARKED_ALCO' || item.code === 'NOT_MARKED_ALCO' ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "select-option-description",
      attrs: {
        "title": "KHJBBJHBJB"
      }
    }, [item.code === 'ALCO' ? _c('div', [_vm._v("Содержит наклеенную акцизную марку")]) : _vm._e(), item.code === 'MARKED_ALCO' ? _c('div', [_vm._v(" Признак маркированной продукции для разливных слабоалкогольных напитков ")]) : _vm._e(), item.code === 'NOT_MARKED_ALCO' ? _c('div', [_vm._v(" Признак слабоалкогольной продукции без требования маркировки ")]) : _vm._e()]) : _vm._e()]);
  }), 1), _c('i-option-group', {
    attrs: {
      "label": "Подакцизная"
    }
  }, _vm._l(_vm.excisableProducts, function (item) {
    return _c('i-option', {
      key: item.key,
      attrs: {
        "value": item.key,
        "label": item.label === 'Подакцизная' ? item.label : `Подакцизная: ${item.label}`
      }
    }, [_c('div', [_vm._v(_vm._s(item.label))]), item.code === 'ALCO' || item.code === 'MARKED_ALCO' || item.code === 'NOT_MARKED_ALCO' ? _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.top",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "select-option-description",
      attrs: {
        "title": "JHBHBBIJHBHG"
      }
    }, [item.code === 'ALCO' ? _c('div', [_vm._v("Содержит наклеенную акцизную марку")]) : _vm._e(), item.code === 'MARKED_ALCO' ? _c('div', [_vm._v(" Признак маркированной продукции для разливных слабоалкогольных напитков ")]) : _vm._e(), item.code === 'NOT_MARKED_ALCO' ? _c('div', [_vm._v(" Признак слабоалкогольной продукции без требования маркировки ")]) : _vm._e()]) : _vm._e()]);
  }), 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "4",
      "lg": "6",
      "sm": "12"
    }
  }, [_c('label', [_vm._v("Налоговая группа")]), _c('i-select', {
    model: {
      value: _vm.form.taxRate.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.taxRate, "id", $$v);
      },
      expression: "form.taxRate.id"
    }
  }, _vm._l(_vm.taxRateList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    attrs: {
      "col": "",
      "lg": "4",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Страна")]), _c('i-select', {
    attrs: {
      "filterable": ""
    },
    model: {
      value: _vm.form.country.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.country, "id", $$v);
      },
      expression: "form.country.id"
    }
  }, _vm._l(_vm.countryList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " (" + _vm._s(item.iso) + ") ")]);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "col": "",
      "lg": "4",
      "md": "4"
    }
  }, [_c('e-select', {
    staticClass: "manufacturer-select",
    attrs: {
      "label": "Производитель",
      "selected_type": "text",
      "show_bottom_button": "",
      "show_edit": "",
      "position": "bottom",
      "search_in_drop": "",
      "placeholder": "Выбрать",
      "placeholder-drop": "Поиск",
      "options": _vm.manufacturer_list,
      "serchable": false
    },
    on: {
      "click_edit": _vm.clickEditManufacturer,
      "click_bottom": _vm.createManufacturer
    },
    scopedSlots: _vm._u([{
      key: "custom_name",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "manufacturer"
        }, [_c('p', {
          staticClass: "name"
        }, [_vm._v(_vm._s(item.name))]), _c('div', {
          staticClass: "inn_kpp"
        }, [_c('p', {
          staticClass: "inn"
        }, [_vm._v("ИНН: " + _vm._s(item.inn ? item.inn : '-'))]), _c('p', {
          staticClass: "kpp"
        }, [_vm._v("КПП: " + _vm._s(item.kpp ? item.kpp : '-'))])])])];
      }
    }]),
    model: {
      value: _vm.selected_manufacturer,
      callback: function ($$v) {
        _vm.selected_manufacturer = $$v;
      },
      expression: "selected_manufacturer"
    }
  }), _c('create-update-manufacturer', {
    attrs: {
      "manufacturer_list": _vm.manufacturer_list,
      "manufacturer": _vm.edit_manufacturer
    },
    on: {
      "close": function ($event) {
        _vm.edit_manufacturer = null;
      },
      "setManufacturer": _vm.setManufacturer,
      "refetch_entity": function ($event) {
        return _vm.$apollo.queries.Entities.refresh();
      }
    }
  })], 1), _c('b-col', {
    attrs: {
      "col": "",
      "lg": "4",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Поставщик")]), _c('i-select', {
    attrs: {
      "filterable": "",
      "clearable": ""
    },
    model: {
      value: _vm.form.supplier.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.supplier, "id", $$v);
      },
      expression: "form.supplier.id"
    }
  }, _vm._l(_vm.supplierList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0 pb-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v(" Расположение, группа "), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "b-select-group",
    class: {
      'b-select-group__error': _vm.categoryError
    },
    staticStyle: {
      "padding-right": "30px",
      "word-wrap": "break-word",
      "overflow": "hidden",
      "white-space": "nowrap"
    },
    on: {
      "click": _vm.openModalSelectGroup
    }
  }, [_c('div', {
    staticClass: "image-group"
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/products/pencil-edit.svg",
      "alt": "groupIcon"
    }
  })]), _vm._v(" " + _vm._s((_this$form$category = this.form.category) !== null && _this$form$category !== void 0 && _this$form$category.name ? (_this$form$category2 = this.form.category) === null || _this$form$category2 === void 0 ? void 0 : _this$form$category2.name : this.currentCategory.name ? this.currentCategory.name : 'Без категории') + " ")])]), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Срок годности, Дней")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.form.expiration_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "expiration_date", $$v);
      },
      expression: "form.expiration_date"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Объём, Л")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "formatter": _vm.formatter_price
    },
    model: {
      value: _vm.form.volume,
      callback: function ($$v) {
        _vm.$set(_vm.form, "volume", $$v);
      },
      expression: "form.volume"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Тип товарной карточки")]), _c('i-select', {
    model: {
      value: _vm.form.productType.id,
      callback: function ($$v) {
        _vm.$set(_vm.form.productType, "id", $$v);
      },
      expression: "form.productType.id"
    }
  }, _vm._l(_vm.productTypeList, function (item) {
    return _c('i-option', {
      key: item.id,
      attrs: {
        "value": item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1)], 1)])])], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "card pt-0 pb-0"
  }, [_c('div', {
    staticClass: "row align-items-end"
  }, [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8' ? 'Закупочная цена, ₽' : 'Себестоимость, ₽') + " ")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.form.productType.id !== '52a4eab0-55c2-49f1-a152-f02cbc61e0b8',
      "formatter": _vm.formatter_price,
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.purchasePrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "purchasePrice", $$v);
      },
      expression: "form.purchasePrice"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v("Наценка, %")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.markup,
      callback: function ($$v) {
        _vm.$set(_vm.form, "markup", $$v);
      },
      expression: "form.markup"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v(" Розничная цена, ₽ ")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "formatter": _vm.formatter_price,
      "placeholder": "0.00"
    },
    model: {
      value: _vm.form.retailPrice,
      callback: function ($$v) {
        _vm.$set(_vm.form, "retailPrice", $$v);
      },
      expression: "form.retailPrice"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "lg": "3",
      "md": "4"
    }
  }, [_c('label', [_vm._v(" Округление ")]), _c('i-select', {
    model: {
      value: _vm.form.round_to,
      callback: function ($$v) {
        _vm.$set(_vm.form, "round_to", $$v);
      },
      expression: "form.round_to"
    }
  }, _vm._l(_vm.round, function (item) {
    return _c('i-option', {
      key: item.value,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1)])])], 1)], 1), _c('b-tabs', {
    attrs: {
      "fill": "",
      "active-nav-item-class": "text-dark font-weight-bold"
    },
    model: {
      value: _vm.tabIndex,
      callback: function ($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Штрих-коды"
    }
  }, [_c('div', {
    attrs: {
      "id": "tab-barcode"
    }
  }, [_c('b-row', {
    staticClass: "b-barcode-header"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "b-barcode-scan"
  })]), _c('b-col', [_c('div', {
    staticClass: "b-barcode-btns d-flex justify-content-end"
  }, [_c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-barcode",
      modifiers: {
        "modal-barcode": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить штрих-код ")]), _c('b-dropdown', {
    staticClass: "ml-3",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/more.svg",
            "alt": ""
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.showPrefixModal
    }
  }, [_vm._v(" Изменить префикс весового штрих-кода")])], 1)], 1)])], 1), _c('modal-barcode', {
    ref: "modal_barcode",
    attrs: {
      "barcodes": _vm.barcodes,
      "ean": _vm.ean
    },
    on: {
      "add-barcode": _vm.addBarcode
    }
  }), _c('b-card', {
    staticClass: "mt-3 p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "table_barcodes",
    staticClass: "table-barcodes",
    attrs: {
      "fields": _vm.fields,
      "items": _vm.barcodes.filter(function (el) {
        return el.barcode;
      }),
      "busy": _vm.tableIsBusy,
      "empty-filtered-text": "Нет штрих-кодов",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "cell(barcode)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(data.value.barcode) + " ")];
      }
    }, {
      key: "cell(value)",
      fn: function (data) {
        var _data$item$barcode;

        return [_vm.isEditPackageBarcode !== ((_data$item$barcode = data.item.barcode) === null || _data$item$barcode === void 0 ? void 0 : _data$item$barcode.id) ? [_c('div', {
          staticClass: "d-flex align-items-center cursor",
          on: {
            "click": function ($event) {
              var _data$item$barcode2;

              return _vm.toggleEditPackageBarcode(data.value, (_data$item$barcode2 = data.item.barcode) === null || _data$item$barcode2 === void 0 ? void 0 : _data$item$barcode2.id);
            }
          }
        }, [_vm._v(" " + _vm._s(data.value) + " "), _c('img', {
          staticClass: "ml-2",
          attrs: {
            "src": "/img/icons/icon_pencil_edit.svg",
            "alt": "Редактировать количество"
          }
        })])] : [_c('b-form-input', {
          staticClass: "edit-package-barcode",
          attrs: {
            "type": "number"
          },
          on: {
            "keyup": function ($event) {
              if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
              return _vm.handlerUpdatePackageBarcode(data);
            }
          },
          model: {
            value: _vm.editPackageBarcode,
            callback: function ($$v) {
              _vm.editPackageBarcode = $$v;
            },
            expression: "editPackageBarcode"
          }
        })]];
      }
    }, {
      key: "empty",
      fn: function (data) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(" " + _vm._s(data.emptyFilteredText) + " ")])];
      }
    }, {
      key: "cell(tools)",
      fn: function (data) {
        return [_c('button', {
          staticClass: "btn icon",
          on: {
            "click": function ($event) {
              return _vm.removeBarcode(data);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/trash.svg",
            "alt": "",
            "width": "16",
            "height": "16"
          }
        })])];
      }
    }])
  })], 1)], 1)]), _c('b-tab', {
    attrs: {
      "title": "Внешний код контрагента"
    }
  }, [_c('external-id-modal', {
    attrs: {
      "product_id": _vm.product_id,
      "supplier_list": _vm.supplierList,
      "edit_id": _vm.extId
    },
    on: {
      "created": _vm.refetch,
      "add_external": _vm.addExternal,
      "close-edit": _vm.closeEdit
    }
  }), _c('div', {
    attrs: {
      "id": "tab-barcode"
    }
  }, [_c('b-row', {
    staticClass: "b-barcode-header"
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-between align-middle"
  }, [_c('div', {
    staticClass: "ext-caption"
  }, [_c('img', {
    staticClass: "mr-2",
    attrs: {
      "src": "/img/icons/info_blue.svg"
    }
  }), _c('span', {
    staticClass: "text-dark"
  }, [_vm._v("Добавляйте внешние коды товаров от поставщиков, чтобы система верно сопоставляла позиции при импорте ")])]), _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.external-id-modal",
      modifiers: {
        "external-id-modal": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить ")])], 1)])], 1), _c('b-card', {
    staticClass: "mt-3 p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "table_barcodes",
    staticClass: "table-barcodes",
    attrs: {
      "fields": _vm.ext_id_fields,
      "items": _vm.form.productExternalId,
      "busy": _vm.externalBusy,
      "empty-filtered-text": "Нет внешних кодов",
      "show-empty": ""
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary",
            "label": "Загрузка..."
          }
        }), _c('div', [_c('strong', [_vm._v("Загрузка...")])])], 1)];
      },
      proxy: true
    }, {
      key: "cell(supplier)",
      fn: function (_ref2) {
        var _vm$supplierList$find, _vm$supplierList$find2;

        var item = _ref2.item;
        return [_vm._v(" " + _vm._s((_vm$supplierList$find = (_vm$supplierList$find2 = _vm.supplierList.find(function (el) {
          return el.id === item.supplier;
        })) === null || _vm$supplierList$find2 === void 0 ? void 0 : _vm$supplierList$find2.name) !== null && _vm$supplierList$find !== void 0 ? _vm$supplierList$find : '-') + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('b-dropdown', {
          attrs: {
            "no-caret": "",
            "variant": "none"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('img', {
                attrs: {
                  "src": "/img/icons/more.svg"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.editExt(item);
            }
          }
        }, [_vm._v(" Редактировать")]), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.removeExt(item.id);
            }
          }
        }, [_vm._v(" Удалить")])], 1)];
      }
    }, {
      key: "empty",
      fn: function (data) {
        return [_c('p', {
          staticClass: "center mt-3"
        }, [_vm._v(" " + _vm._s(data.emptyFilteredText) + " ")])];
      }
    }])
  })], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Упаковки"
    }
  }, [_c('package-modal', {
    attrs: {
      "ean": _vm.ean,
      "barcodes": _vm.barcodes,
      "pkg": _vm.pckg
    },
    on: {
      "clearPckg": _vm.clearPckg,
      "add_package": _vm.addPackage
    }
  }), _c('div', {
    attrs: {
      "id": "tab-barcode"
    }
  }, [_c('b-row', {
    staticClass: "b-barcode-header"
  }, [_c('b-col', [_c('div', {
    staticClass: "d-flex justify-content-end align-middle"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.openPckg
    }
  }, [_c('img', {
    staticClass: "mr",
    attrs: {
      "src": "/img/icons/btn-plus.svg",
      "alt": ""
    }
  }), _vm._v(" Добавить упаковку ")])], 1)])], 1), _c('b-card', {
    staticClass: "mt-3 p-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('package-table', {
    attrs: {
      "packages": _vm.barcodes
    },
    on: {
      "edit_package": _vm.editPckg,
      "refresh": _vm.refetch,
      "remove_package": _vm.removeBarcode
    }
  })], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Весы"
    }
  }, [_c('scale-tab', {
    staticStyle: {
      "margin-top": "22px"
    },
    attrs: {
      "barcodes": _vm.barcodes,
      "measurement": _vm.form.measurement,
      "product": _vm.form,
      "ean": _vm.ean,
      "product_id": _vm.product_id
    },
    on: {
      "refetch": _vm.refetch,
      "add-barcode": _vm.addBarcode
    }
  })], 1)], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Калькуляция"
    }
  }, [_c('compound-table', {
    attrs: {
      "product": _vm.product_id
    },
    on: {
      "handlerProduct": _vm.handlerSaveProduct
    }
  })], 1), (_vm$form$ext_system = _vm.form.ext_system) !== null && _vm$form$ext_system !== void 0 && (_vm$form$ext_system$m = _vm$form$ext_system.mercury) !== null && _vm$form$ext_system$m !== void 0 && _vm$form$ext_system$m.length || (_vm$form$ext_system2 = _vm.form.ext_system) !== null && _vm$form$ext_system2 !== void 0 && (_vm$form$ext_system2$ = _vm$form$ext_system2.other) !== null && _vm$form$ext_system2$ !== void 0 && _vm$form$ext_system2$.length || (_vm$form$ext_system3 = _vm.form.ext_system) !== null && _vm$form$ext_system3 !== void 0 && (_vm$form$ext_system3$ = _vm$form$ext_system3.egais) !== null && _vm$form$ext_system3$ !== void 0 && _vm$form$ext_system3$.length ? _c('b-tab', {
    attrs: {
      "title": "Внешние системы"
    }
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('mercury-ext-card', {
    attrs: {
      "products": (_vm$form$ext_system$m2 = (_vm$form$ext_system4 = _vm.form.ext_system) === null || _vm$form$ext_system4 === void 0 ? void 0 : _vm$form$ext_system4.mercury) !== null && _vm$form$ext_system$m2 !== void 0 ? _vm$form$ext_system$m2 : [],
      "product": _vm.product_id
    },
    on: {
      "refresh": _vm.refetch
    }
  }), _c('crptech-ext-card', {
    attrs: {
      "product": _vm.product_id,
      "products": (_vm$form$ext_system$o = (_vm$form$ext_system5 = _vm.form.ext_system) === null || _vm$form$ext_system5 === void 0 ? void 0 : _vm$form$ext_system5.other) !== null && _vm$form$ext_system$o !== void 0 ? _vm$form$ext_system$o : []
    },
    on: {
      "refresh": _vm.refetch
    }
  }), _c('egais-ext-card', {
    attrs: {
      "product": _vm.product_id,
      "products": (_vm$form$ext_system$e = (_vm$form$ext_system6 = _vm.form.ext_system) === null || _vm$form$ext_system6 === void 0 ? void 0 : _vm$form$ext_system6.egais) !== null && _vm$form$ext_system$e !== void 0 ? _vm$form$ext_system$e : []
    },
    on: {
      "refresh": _vm.refetch
    }
  })], 1)]) : _vm._e()], 1)], 1)], 1), _c('no-barcode', {
    ref: "modal_no_barcode",
    on: {
      "showAddBarcode": _vm.showAddBarcode,
      "handlerSaveProduct": _vm.handlerSaveProduct
    }
  }), _c('modal-select-group', {
    ref: "modal_select_group",
    attrs: {
      "set_category": _vm.setCategory,
      "category": _vm.form.category,
      "modal_show": _vm.modal_select_group
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }