<template>
  <div>
    <plu-product-list-modal
      :list_name="list_name"
      :list_id="list_id"
    />
    <add-scales-modal
      :barcodes="barcodes"
      :product_id="product_id"
      :product="product"
      @add-barcode="addBarcode"
      @refetch="$emit('refetch')"
    />
    <div class="add-button-block w-100 d-flex justify-content-end">
      <b-tooltip
        v-if="disabled"
        target="button-block"
      >
        Укажите ед. измерения кг, чтобы добавить весы
      </b-tooltip>
      <div id="button-block">
        <e-button
          :variant="variantAddButton"
          plus
          size="m"
          :disabled="disabled"
          @click="addScales"
        >
          Добавить
        </e-button>
      </div>
    </div>
    <b-card
      no-body
      class="p-0"
    >
      <b-table
        :fields="fields"
        :items="product.plu"
      >
        <template #cell(name)="{ item }">
          <div
            class="list-cell p7 d-flex align-items-center"
            @click="openList(item)"
          >
            <img
              class="mr-2"
              src="/img/icons/list.svg"
              alt="Список"
            />
            <p class="m-0">{{ item?.list?.name }}</p>
          </div>
        </template>
        <template #cell(plu)="data">
          <div class="p7">
            {{ data.value }}
          </div>
        </template>
        <template #cell(actions)="{ item }">
          <div
            class="p7"
            style="cursor: pointer"
            @click="removePlu(item)"
          >
            <img
              src="/img/icons/bin.svg"
              alt="bin"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <b-card
      no-body
      class="p-4"
    >
      <div v-if="description?.id">
        <div class="desc-text">Описание товара на весах</div>

        <b-form-textarea
          v-model="description.description"
          style="width: 100%"
          rows="3"
          @input="updateDesc"
        />
      </div>

      <div
        v-else
        class="btn-add cursor"
        @click="addDesc"
      >
        <img
          src="/img/icons/plus_normal.svg"
          class="mr-2"
          alt=""
        />
        Добавить описание
      </div>
    </b-card>
  </div>
</template>

<script>
  import { ProductModel } from '@/models/product.model'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import { mapGetters } from 'vuex'
  import { ProductDescriptionModel } from '@/models/product-description.model'
  import AddScalesModal from '@/views/products/modal/AddScalesModal'
  import PluProductListModal from '@/views/settings/components/PluProductListModal'

  export default {
    name: 'ScaleTab',
    components: { PluProductListModal, AddScalesModal, TableRowInput },
    props: {
      barcodes: {
        type: Array,
        default: () => []
      },
      product: ProductModel,
      measurement: {
        type: Object,
        default: () => {}
      },
      product_id: {
        type: String,
        default: ''
      },
      ean: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        list_name: '',
        list_id: '',
        timer: null,
        description: new ProductDescriptionModel(),
        fields: [
          {
            key: 'name',
            label: 'Список',
            thStyle: 'width: 250px'
          },
          {
            key: 'plu',
            label: 'PLU-код',
            thStyle: 'width: 130px'
          },
          {
            key: 'status',
            label: ''
          },
          {
            key: 'actions',
            label: 'Действия',
            thStyle: 'width: 100px'
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      disabled() {
        return this.ean !== 'weight'
      },
      variantAddButton() {
        return this.disabled ? 'default' : 'primary'
      }
    },
    watch: {
      'product.productDescription': function (newVal) {
        const obj = newVal?.find((el) => el.tags.includes('scales'))
        if (obj) this.description = obj
      }
    },
    mounted() {
      this.description =
        this.product.productDescription.find((el) => el.tags.includes('scales')) ||
        new ProductDescriptionModel({
          branch: this.currentBranch.id,
          product: this.product.id,
          tags: ['scales'],
          integration: 'device',
          description: ''
        })
    },
    methods: {
      openList(item) {
        this.list_id = item?.list?.id
        this.list_name = item?.name
        this.$bvModal.show('plu_product_list_modal')
      },
      async removePlu(item) {
        try {
          await this.$apollo.mutate({
            mutation: require('../../settings/gql/RemovePlus.graphql'),
            variables: {
              ids: [item.id]
            }
          })
        } catch (e) {
          this.$noty.show('Ошибка при удалении PLU')
        }
        this.$emit('refetch')
      },
      addBarcode(val) {
        this.$emit('add-barcode', val)
      },
      addScales() {
        this.$bvModal.show('add-scales-modal')
      },
      async addDesc() {
        const { data } = await this.$apollo.mutate({
          mutation: require('../gql/createDescription.graphql'),
          variables: {
            input: { ...this.description, product: this.product.id }
          }
        })
        this.product.setProductDescription(data?.CreateProductDescription)
      },
      updateDesc() {
        clearTimeout(this.timer)
        this.timer = setTimeout(async () => {
          try {
            this.product.setProductDescription(this.description)
            await this.$apollo.mutate({
              mutation: require('../gql/updateDescription.graphql'),
              variables: {
                input: { ...this.description, product: this.product.id }
              }
            })
          } catch (e) {
            this.$noty.error('Ошибка сохранения')
          }
        }, 300)
      },
      async updatePlu(val, row) {
        row.plu = val
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/updatePlu.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                product: this.product.id,
                plu: val + '',
                device: row.device?.id
              }
            }
          })
        } catch (e) {
          this.$noty.error('Ошибка сохранения')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-button-block {
    margin-bottom: 16px;
  }
  .btn-add {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #00a3ff;
  }
  .desc-text {
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
    color: #313131;
  }
  .list-cell {
    cursor: pointer;
    p {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .p7 {
    padding: 7px 0;
  }
</style>
