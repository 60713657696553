<template>
  <b-modal
    id="create-update-manufacturer"
    centered
    hide-header
    hide-footer
    @hide="hideModal"
  >
    <div class="modal-manufacturer">
      <div class="title-block">
        <h5 style="margin-bottom: 0">
          {{ manufacturer?.id ? 'Редактирование производителя' : 'Новый производитель' }}
        </h5>
        <div class="d-flex ml-2">
          <b-dropdown
            v-if="manufacturer?.id"
            no-caret
            variant="none"
            class="mr-2"
          >
            <template #button-content>
              <div class="btn-more">
                <img
                  style="transform: rotate(90deg)"
                  src="/img/icons/Dots.svg"
                  alt="Dots"
                />
              </div>
            </template>
            <b-dropdown-item @click="deleteManufacturer">Удалить</b-dropdown-item>
          </b-dropdown>
          <e-button
            class="close"
            @click="hideModal()"
          >
            <img
              src="/img/icons/modal_close.svg"
              alt=""
            />
          </e-button>
        </div>
      </div>
      <div class="body-block">
        <div class="manufacturer-block">
          <div class="title">Основные данные</div>
          <div class="form-block">
            <div class="group-block">
              <div class="group">
                <p class="group-label">Наименование<span class="color-red">*</span></p>
                <e-input
                  v-model="form.full_name"
                  :error="errorFullname"
                  error_text="Наименование обязательное поле"
                  required
                  placeholder="Наименование"
                />
              </div>
              <div class="group">
                <p class="group-label">Краткое наименование</p>
                <e-input
                  v-model="form.name"
                  placeholder="Краткое наименование"
                />
              </div>
            </div>
            <div class="group-block">
              <div class="group">
                <p class="group-label">ИНН</p>
                <e-input
                  v-model="form.inn"
                  :error="errorInn"
                  error_text="Производитель с таким ИНН уже сущевствует"
                  placeholder="ИНН"
                  :minlength="10"
                  :maxlength="12"
                  @keyup="searchEntity"
                />
                <template v-if="inn_search_result">
                  <apollo-query
                    :query="require('../../contractors/gql/getEntityDadata.gql')"
                    :variables="{ inn: form.inn }"
                    :skip="form.inn < 10"
                    fetch-policy="no-cache"
                  >
                    <template #default="{ result: { error, data }, isLoading }">
                      <div
                        v-if="isLoading"
                        class="b-search-result p-2 center"
                      >
                        <b-spinner variant="primary" />
                      </div>
                      <div
                        v-else-if="error"
                        class="error apollo"
                      >
                        Ошибка при запросе
                      </div>
                      <div
                        v-else-if="data"
                        class="b-search-result"
                      >
                        <div
                          v-for="(item, index) in data.Dadata"
                          :key="index"
                          class="item cursor"
                          @click="selectInn(item)"
                        >
                          <div class="item-name">{{ item.name }}</div>
                          <div class="item-info">
                            ИНН: {{ item.inn }} КПП: {{ item.kpp }} {{ item.address_entity.city }},
                            {{ item.address_entity.country }}, {{ item.address_entity.post_index }}, улица
                            {{ item.address_entity.street }},
                            {{ item.address_entity.apartment }}
                          </div>
                        </div>
                      </div>
                      <div
                        v-else
                        class="b-search-result p-3 center"
                      >
                        Нет результата
                      </div>
                    </template>
                  </apollo-query>
                </template>
              </div>
              <div class="group">
                <p class="group-label">КПП</p>
                <e-input
                  v-model="form.kpp"
                  placeholder="КПП"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="address-block">
          <div class="address">
            <div class="title-block">
              <div class="address__title">Юридический адрес</div>
              <div
                v-if="isOpenEntityAddress"
                class="delete"
                @click="deleteEntityAddress"
              >
                Удалить
                <img
                  src="/img/icons/bin.svg"
                  alt="delete"
                />
              </div>
            </div>
            <e-button
              v-if="!isOpenEntityAddress"
              class="link-button"
              plus
              size="m"
              variant="link"
              @click="isOpenEntityAddress = true"
            >
              Добавить адрес
            </e-button>
            <e-collapse
              :show-button="false"
              :value="isOpenEntityAddress"
            >
              <div class="form">
                <div class="row mb-4">
                  <div class="col-4">
                    <label>Страна</label>
                    <e-input
                      v-model="form.address_entity.country"
                      placeholder="Страна"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Город</label>
                    <e-input
                      v-model="form.address_entity.city"
                      placeholder="Город"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Улица</label>
                    <e-input
                      v-model="form.address_entity.street"
                      placeholder="Улица"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label>Дом</label>
                    <e-input
                      v-model="form.address_entity.house"
                      placeholder="Дом"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Квартира/Офис</label>
                    <e-input
                      v-model="form.address_entity.apartment"
                      placeholder="Квартира/Офис"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Почтовый индекс</label>
                    <e-input
                      v-model="form.address_entity.post_index"
                      placeholder="Почтовый индекс"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
            </e-collapse>
          </div>
          <div class="address">
            <div class="title-block">
              <div class="address__title">Фактический адрес</div>
              <div
                v-if="isOpenFactAddress"
                class="delete"
                @click="deleteFactAddress"
              >
                Удалить
                <img
                  src="/img/icons/bin.svg"
                  alt="delete"
                />
              </div>
            </div>
            <e-button
              v-if="!isOpenFactAddress"
              plus
              class="link-button"
              size="m"
              variant="link"
              @click="isOpenFactAddress = true"
            >
              Добавить адрес
            </e-button>
            <e-collapse
              :show-button="false"
              :value="isOpenFactAddress"
            >
              <div
                v-if="isOpenFactAddress"
                class="form"
              >
                <div class="row mb-4">
                  <div class="col-4">
                    <label>Страна</label>
                    <e-input
                      v-model="form.address_fact.country"
                      placeholder="Страна"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Город</label>
                    <e-input
                      v-model="form.address_fact.city"
                      placeholder="Город"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Улица</label>
                    <e-input
                      v-model="form.address_fact.street"
                      placeholder="Улица"
                      type="text"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <label>Дом</label>
                    <e-input
                      v-model="form.address_fact.house"
                      placeholder="Дом"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Квартира/Офис</label>
                    <e-input
                      v-model="form.address_fact.apartment"
                      placeholder="Квартира/Офис"
                      type="text"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <label>Почтовый индекс</label>
                    <e-input
                      v-model="form.address_fact.post_index"
                      placeholder="Почтовый индекс"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
            </e-collapse>
          </div>
        </div>
      </div>
      <div class="footer-block">
        <e-button
          size="m"
          variant="primary"
          @click="saveManufacturer"
        >
          Сохранить
        </e-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import { AddressModel } from '@/models/address.model'
  import { EntityModel } from '@/models/entity.model'

  export default {
    name: 'CreateUpdateManufacturer',
    props: {
      manufacturer_list: {
        type: Array,
        default: () => []
      },
      manufacturer: {
        type: [EntityModel, Object, null],
        default: null
      }
    },
    data() {
      return {
        errorFullname: false,
        errorInn: false,
        inn_search_result: false,
        isOpenEntityAddress: false,
        isOpenFactAddress: false,
        form: {
          name: '',
          full_name: '',
          inn: '',
          kpp: '',
          address_entity: new AddressModel(),
          address_fact: new AddressModel()
        }
      }
    },
    watch: {
      'form.full_name'() {
        if (this.errorFullname) {
          this.errorFullname = false
        }
      },
      'form.inn'() {
        if (this.errorInn) {
          this.errorInn = false
        }
      },
      manufacturer() {
        if (this.manufacturer?.id) {
          if (this.manufacturer?.address_entity.country) {
            this.isOpenEntityAddress = true
          }
          if (this.manufacturer?.address_entity.city) {
            this.isOpenEntityAddress = true
          }
          if (this.manufacturer?.address_entity.street) {
            this.isOpenEntityAddress = true
          }
          if (this.manufacturer?.address_fact.country) {
            this.isOpenFactAddress = true
          }
          if (this.manufacturer?.address_fact.city) {
            this.isOpenFactAddress = true
          }
          if (this.manufacturer?.address_fact.street) {
            this.isOpenFactAddress = true
          }
          this.form = {
            name: this.manufacturer?.name ?? '',
            full_name: this.manufacturer?.full_name ?? '',
            inn: this.manufacturer?.inn ?? '',
            kpp: this.manufacturer?.kpp ?? '',
            address_entity: this.manufacturer?.address_entity ?? new AddressModel(),
            address_fact: this.manufacturer?.address_fact ?? new AddressModel()
          }
        }
      }
    },
    methods: {
      async deleteManufacturer() {
        try {
          await this.$apollo.mutate({
            mutation: require('../gql/RemoveEntities.graphql'),
            fetchPolicy: 'no-cache',
            variables: {
              ids: [this.manufacturer?.id]
            }
          })
          await this.$emit('refetch_entity')
          this.hideModal()
        } catch (e) {
          console.log(e)
          this.$noty.error('При удалении произошла ошибка, попробуйте позже')
        }
      },
      searchEntity() {
        if (this.form.inn.length > 9) return (this.inn_search_result = true)
        this.inn_search_result = false
      },
      selectInn(item) {
        const entity = new EntityModel(item)
        this.form = {
          name: entity.name,
          full_name: entity.fullname,
          inn: entity.inn,
          kpp: entity.kpp,
          address_entity: entity.address_entity,
          address_fact: entity.address_fact
        }
        if (!this.isOpenEntityAddress) {
          this.isOpenEntityAddress = true
        }
        this.inn_search_result = false
      },
      deleteFactAddress() {
        this.isOpenFactAddress = false
        this.form.address_fact = new AddressModel()
      },
      deleteEntityAddress() {
        this.isOpenEntityAddress = false
        this.form.address_entity = new AddressModel()
      },
      hideModal() {
        this.$bvModal.hide('create-update-manufacturer')
        this.clearForm()
        this.$emit('close')
      },
      clearForm() {
        this.form = {
          name: '',
          full_name: '',
          inn: '',
          kpp: '',
          address_entity: new AddressModel(),
          address_fact: new AddressModel()
        }
        this.inn_search_result = false
        this.isOpenEntityAddress = false
        this.isOpenFactAddress = false
      },
      async saveManufacturer() {
        const f = Object.assign({}, this.form)
        if (!f.full_name) {
          this.errorFullname = true
          return this.$noty.error('Наименование обязательное поле')
        }
        f.fullname = f.full_name
        delete f.full_name
        if (this.manufacturer?.id) {
          await this.$apollo.mutate({
            mutation: require('../gql/updateEntity.gql'),
            variables: {
              input: {
                id: this.manufacturer?.id,
                name: f.name,
                fullname: f.fullname,
                inn: f.inn,
                kpp: f.kpp,
                address_entity: new AddressModel(f.address_entity ?? {}),
                address_fact: new AddressModel(f.address_fact ?? {})
              }
            }
          })
          await this.$emit('refetch_entity')
          this.$emit('setManufacturer', { ...this.form, id: this.manufacturer?.id })
        } else {
          if (this.manufacturer_list.find((obj) => obj.inn === this.form.inn) && this.form.inn) {
            this.errorInn = true
            return this.$noty.error('Производитель с таким ИНН уже сущевствует')
          }
          const { data } = await this.$apollo.mutate({
            mutation: require('../gql/createEntity.gql'),
            variables: {
              input: {
                name: f.name,
                fullname: f.fullname,
                inn: f.inn,
                kpp: f.kpp,
                address_entity: new AddressModel(f.address_entity ?? {}),
                address_fact: new AddressModel(f.address_fact ?? {}),
                is_manufacturer: true
              }
            }
          })
          await this.$emit('refetch_entity')
          this.$emit('setManufacturer', { ...this.form, id: data?.CreateEntity?.id })
        }
        this.hideModal()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-manufacturer {
    .title-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        height: fit-content;
        padding: 0;
        border: none;
      }
      margin-bottom: 32px;
    }
  }
  .body-block {
    .manufacturer-block {
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      padding: 22px;
      display: flex;
      flex-direction: column;
      .title {
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #bcbcbc;
      }
      .form-block {
        flex: 1;
        .group-block {
          margin-top: 16px;
          gap: 16px;
          display: flex;
          .group {
            position: relative;
            flex: 1;
            p {
              margin: 0 0 4px 0;
            }
          }
        }
      }
    }
  }
  .address-block {
    .address {
      margin: 16px 0;
      padding: 22px;
      border: 1px solid #e1e1e1;
      border-radius: 6px;
      .title-block {
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .delete {
          cursor: pointer;
        }
      }
      &__title {
        margin-bottom: 0;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #bcbcbc;
      }
    }
  }
  .footer-block {
    padding-bottom: 22px;
    button {
      margin-top: 22px;
    }
  }
  ::v-deep .modal-dialog {
    min-width: 644px;
  }
  .link-button {
    padding: 0;
    text-decoration: none;
  }

  .b-search-result {
    position: absolute;
    z-index: 10;
    top: 70px;
    left: 0;
    width: 490px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    box-shadow: 0px 0px 40px 7px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    .item {
      padding: 8px 14px;

      &-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #313131;
      }

      &-info {
        font-size: 12px;
        line-height: 24px;
        color: #888888;
        height: 24px;
        overflow: hidden;

        &.street {
          font-weight: 500;
          color: #313131;
          font-size: 14px;
        }

        span {
          margin-right: 12px;

          &.post_index {
            margin-right: 0;
            margin-left: 16px;
          }
        }
      }
    }
  }
  :deep p.error_text {
    margin-bottom: 0 !important;
  }
  :deep .btn-more {
    opacity: 0.6 !important;
    background: #efefef !important;
    max-width: 24px !important;
    max-height: 24px !important;
    min-height: 24px !important;
    min-height: 24px !important;
    &:hover {
      opacity: 1 !important;
    }
  }
  :deep .btn {
    height: 24px !important;
  }
</style>
