var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "1.35456",
      "cy": "8.01838",
      "r": "1.32917",
      "fill": _vm.fill
    }
  }), _c('circle', {
    attrs: {
      "cx": "8.00105",
      "cy": "8.01838",
      "r": "1.32917",
      "fill": _vm.fill
    }
  }), _c('circle', {
    attrs: {
      "cx": "14.6475",
      "cy": "8.01838",
      "r": "1.32917",
      "fill": _vm.fill
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }