<template>
  <b-modal
    id="package-modal"
    centered
    small
    @shown="checkPkg"
  >
    <template #modal-header="{ close }">
      <h5>{{ pkg?.id ? 'Изменить' : 'Новая упаковка' }}</h5>
      <b-button
        class="close"
        @click="closes(close)"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </template>
    <div class="form-group">
      <label>Количество</label>
      <b-form-input
        v-model="pckg.value"
        type="number"
        autofocus
      />
    </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 16px">
      <div class="form-group">
        <label>Длина, см</label>
        <b-form-input v-model="pckg.length" />
      </div>
      <div class="form-group">
        <label>Высота, см</label>
        <b-form-input v-model="pckg.height" />
      </div>
      <div class="form-group">
        <label>Ширина, см</label>
        <b-form-input v-model="pckg.width" />
      </div>
    </div>
    <div class="form-group barcode-group">
      <label>Штрих-код</label>
      <!--      <div-->
      <!--        class="b-generate-bc cursor"-->
      <!--        @click="generateBarcode"-->
      <!--      >-->
      <!--        Сгенерировать штрих-код-->
      <!--      </div>-->
      <b-form-input
        v-model="pckg.barcode.barcode"
        class="scan-circle"
        :class="{ error: barcode_error }"
        type="number"
        required
        placeholder="00000000000"
      />
      <img
        class="generate-barcode"
        src="/img/icons/icon_scan_circle.svg"
        alt="Можно считать сканером"
      />
      <p
        v-if="barcode_error === 1"
        class="error mt-1"
      >
        Необходимо ввести штрихкод
      </p>
      <p
        v-if="barcode_error === 2"
        class="error mt-1"
      >
        Такой штрихкод уже существует
      </p>
    </div>
    <template #modal-footer="{ close }">
      <div class="d-flex w-50 justify-content-between">
        <b-button
          variant="primary"
          @click="createPckg"
        >
          Сохранить
        </b-button>
        <b-button
          variant="outline-primary"
          @click="closes(close)"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
  export default {
    name: 'PackageModal',

    props: {
      ean: {
        type: String,
        default: ''
      },
      barcodes: {
        type: Array,
        default: () => []
      },
      pkg: {
        type: [Object, null],
        default: null
      }
    },

    apollo: {
      GenerateBarcode: {
        query: require('../gql/GenerateBarcode.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            ean: this.ean
          }
        },
        result({ data }) {
          this.pckg.barcode = data?.GenerateBarcode ?? ''
          this.ean_generate = true
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return this.ean_generate
        }
      }
    },

    data() {
      return {
        barcode_error: null,
        ean_generate: true,
        pckg: {
          id: null,
          barcode: { barcode: '' },
          length: '',
          width: '',
          height: '',
          value: ''
        }
      }
    },
    methods: {
      checkPkg() {
        if (!this.pkg) {
          this.pckg = {
            id: null,
            barcode: { barcode: '' },
            length: '',
            width: '',
            height: '',
            value: ''
          }
        } else this.pckg = { ...this.pkg, barcode: this.pkg.barcode || { barcode: '' } }
      },

      closes(close) {
        this.$emit('clearPckg')
        close()
      },

      generateBarcode() {
        this.ean_generate = false
        this.$apollo.queries.GenerateBarcode.refetch()
      },
      createPckg() {
        this.pckg = {
          ...this.pckg,
          length: +this.pckg.length,
          width: +this.pckg.width,
          height: +this.pckg.height,
          value: +this.pckg.value
        }
        this.$emit('add_package', this.pckg)
        this.$emit('clearPckg')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .barcode-group {
    position: relative;

    .b-generate-bc {
      font-weight: 400;
      font-size: 14px;
      color: #0066ff;
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: underline;
    }

    img.generate-barcode {
      position: absolute;
      top: 30px;
      right: 10px;
    }
  }
</style>
