<template>
  <div class="warning-container">
    <div class="warning-container__left">
      <slot name="left">
        <warning-svg
          color="#313131"
          class="warning-img"
        />
        <p><slot name="title_warning">Товар отсутствует на весах</slot></p>
      </slot>
    </div>
    <div class="warning-container__right">
      <slot name="right">
        <e-button
          size="m"
          @click="$emit('set_skip_warning')"
        >
          Пропустить
        </e-button>
        <e-button
          size="m"
          @click="$emit('open_modal_scale')"
        >
          Добавить на весы
        </e-button>
        <b-dropdown
          no-caret
          variant="secondary"
        >
          <template #button-content>
            <img
              class="dots-img"
              src="/img/icons/Dots.svg"
              alt="dots"
            />
          </template>
          <b-dropdown-item @click="hide">Не показывать больше</b-dropdown-item>
        </b-dropdown>
      </slot>
    </div>
  </div>
</template>

<script>
  import WarningSvg from '@/views/core/header/components/WarningSvg'
  export default {
    name: 'WarningList',
    components: { WarningSvg },
    methods: {
      hide() {
        this.$emit('set_skip_warning')
        localStorage.setItem('hideWarningScale', 'true')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .warning-container {
    padding: 11px 21px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ffa800;
    width: 100%;
    height: 54px;
    &__left {
      display: flex;
      p {
        margin: 0 0 0 9px;
        font-family: 'Gilroy-Medium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #313131;
      }
    }
    &__right {
      height: 32px;
      display: flex;
      .dots-img {
        transform: rotate(90deg);
      }
      ::v-deep .btn-secondary {
        background-color: #ffbf44;
      }
      ::v-deep .dropdown-menu {
        top: -22px !important;
      }
      button {
        color: #313131;
        border-color: #ffbf44;
        background-color: #ffbf44;
        &:nth-child(2) {
          margin: 0 12px;
        }
      }
    }
  }
</style>
