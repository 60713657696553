var render = function render(){
  var _vm$manufacturer, _vm$manufacturer2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-update-manufacturer",
      "centered": "",
      "hide-header": "",
      "hide-footer": ""
    },
    on: {
      "hide": _vm.hideModal
    }
  }, [_c('div', {
    staticClass: "modal-manufacturer"
  }, [_c('div', {
    staticClass: "title-block"
  }, [_c('h5', {
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._v(" " + _vm._s((_vm$manufacturer = _vm.manufacturer) !== null && _vm$manufacturer !== void 0 && _vm$manufacturer.id ? 'Редактирование производителя' : 'Новый производитель') + " ")]), _c('div', {
    staticClass: "d-flex ml-2"
  }, [(_vm$manufacturer2 = _vm.manufacturer) !== null && _vm$manufacturer2 !== void 0 && _vm$manufacturer2.id ? _c('b-dropdown', {
    staticClass: "mr-2",
    attrs: {
      "no-caret": "",
      "variant": "none"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-more"
        }, [_c('img', {
          staticStyle: {
            "transform": "rotate(90deg)"
          },
          attrs: {
            "src": "/img/icons/Dots.svg",
            "alt": "Dots"
          }
        })])];
      },
      proxy: true
    }], null, false, 725609748)
  }, [_c('b-dropdown-item', {
    on: {
      "click": _vm.deleteManufacturer
    }
  }, [_vm._v("Удалить")])], 1) : _vm._e(), _c('e-button', {
    staticClass: "close",
    on: {
      "click": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/modal_close.svg",
      "alt": ""
    }
  })])], 1)]), _c('div', {
    staticClass: "body-block"
  }, [_c('div', {
    staticClass: "manufacturer-block"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Основные данные")]), _c('div', {
    staticClass: "form-block"
  }, [_c('div', {
    staticClass: "group-block"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('p', {
    staticClass: "group-label"
  }, [_vm._v("Наименование"), _c('span', {
    staticClass: "color-red"
  }, [_vm._v("*")])]), _c('e-input', {
    attrs: {
      "error": _vm.errorFullname,
      "error_text": "Наименование обязательное поле",
      "required": "",
      "placeholder": "Наименование"
    },
    model: {
      value: _vm.form.full_name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "full_name", $$v);
      },
      expression: "form.full_name"
    }
  })], 1), _c('div', {
    staticClass: "group"
  }, [_c('p', {
    staticClass: "group-label"
  }, [_vm._v("Краткое наименование")]), _c('e-input', {
    attrs: {
      "placeholder": "Краткое наименование"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)]), _c('div', {
    staticClass: "group-block"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('p', {
    staticClass: "group-label"
  }, [_vm._v("ИНН")]), _c('e-input', {
    attrs: {
      "error": _vm.errorInn,
      "error_text": "Производитель с таким ИНН уже сущевствует",
      "placeholder": "ИНН",
      "minlength": 10,
      "maxlength": 12
    },
    on: {
      "keyup": _vm.searchEntity
    },
    model: {
      value: _vm.form.inn,
      callback: function ($$v) {
        _vm.$set(_vm.form, "inn", $$v);
      },
      expression: "form.inn"
    }
  }), _vm.inn_search_result ? [_c('apollo-query', {
    attrs: {
      "query": require("../../contractors/gql/getEntityDadata.gql"),
      "variables": {
        inn: _vm.form.inn
      },
      "skip": _vm.form.inn < 10,
      "fetch-policy": "no-cache"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var _ref$result = _ref.result,
            error = _ref$result.error,
            data = _ref$result.data,
            isLoading = _ref.isLoading;
        return [isLoading ? _c('div', {
          staticClass: "b-search-result p-2 center"
        }, [_c('b-spinner', {
          attrs: {
            "variant": "primary"
          }
        })], 1) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" Ошибка при запросе ")]) : data ? _c('div', {
          staticClass: "b-search-result"
        }, _vm._l(data.Dadata, function (item, index) {
          return _c('div', {
            key: index,
            staticClass: "item cursor",
            on: {
              "click": function ($event) {
                return _vm.selectInn(item);
              }
            }
          }, [_c('div', {
            staticClass: "item-name"
          }, [_vm._v(_vm._s(item.name))]), _c('div', {
            staticClass: "item-info"
          }, [_vm._v(" ИНН: " + _vm._s(item.inn) + " КПП: " + _vm._s(item.kpp) + " " + _vm._s(item.address_entity.city) + ", " + _vm._s(item.address_entity.country) + ", " + _vm._s(item.address_entity.post_index) + ", улица " + _vm._s(item.address_entity.street) + ", " + _vm._s(item.address_entity.apartment) + " ")])]);
        }), 0) : _c('div', {
          staticClass: "b-search-result p-3 center"
        }, [_vm._v(" Нет результата ")])];
      }
    }], null, false, 708167889)
  })] : _vm._e()], 2), _c('div', {
    staticClass: "group"
  }, [_c('p', {
    staticClass: "group-label"
  }, [_vm._v("КПП")]), _c('e-input', {
    attrs: {
      "placeholder": "КПП"
    },
    model: {
      value: _vm.form.kpp,
      callback: function ($$v) {
        _vm.$set(_vm.form, "kpp", $$v);
      },
      expression: "form.kpp"
    }
  })], 1)])])]), _c('div', {
    staticClass: "address-block"
  }, [_c('div', {
    staticClass: "address"
  }, [_c('div', {
    staticClass: "title-block"
  }, [_c('div', {
    staticClass: "address__title"
  }, [_vm._v("Юридический адрес")]), _vm.isOpenEntityAddress ? _c('div', {
    staticClass: "delete",
    on: {
      "click": _vm.deleteEntityAddress
    }
  }, [_vm._v(" Удалить "), _c('img', {
    attrs: {
      "src": "/img/icons/bin.svg",
      "alt": "delete"
    }
  })]) : _vm._e()]), !_vm.isOpenEntityAddress ? _c('e-button', {
    staticClass: "link-button",
    attrs: {
      "plus": "",
      "size": "m",
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.isOpenEntityAddress = true;
      }
    }
  }, [_vm._v(" Добавить адрес ")]) : _vm._e(), _c('e-collapse', {
    attrs: {
      "show-button": false,
      "value": _vm.isOpenEntityAddress
    }
  }, [_c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Страна")]), _c('e-input', {
    attrs: {
      "placeholder": "Страна",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.country,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "country", $$v);
      },
      expression: "form.address_entity.country"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Город")]), _c('e-input', {
    attrs: {
      "placeholder": "Город",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.city,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "city", $$v);
      },
      expression: "form.address_entity.city"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Улица")]), _c('e-input', {
    attrs: {
      "placeholder": "Улица",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.street,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "street", $$v);
      },
      expression: "form.address_entity.street"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Дом")]), _c('e-input', {
    attrs: {
      "placeholder": "Дом",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.house,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "house", $$v);
      },
      expression: "form.address_entity.house"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Квартира/Офис")]), _c('e-input', {
    attrs: {
      "placeholder": "Квартира/Офис",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.apartment,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "apartment", $$v);
      },
      expression: "form.address_entity.apartment"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Почтовый индекс")]), _c('e-input', {
    attrs: {
      "placeholder": "Почтовый индекс",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_entity.post_index,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_entity, "post_index", $$v);
      },
      expression: "form.address_entity.post_index"
    }
  })], 1)])])])], 1), _c('div', {
    staticClass: "address"
  }, [_c('div', {
    staticClass: "title-block"
  }, [_c('div', {
    staticClass: "address__title"
  }, [_vm._v("Фактический адрес")]), _vm.isOpenFactAddress ? _c('div', {
    staticClass: "delete",
    on: {
      "click": _vm.deleteFactAddress
    }
  }, [_vm._v(" Удалить "), _c('img', {
    attrs: {
      "src": "/img/icons/bin.svg",
      "alt": "delete"
    }
  })]) : _vm._e()]), !_vm.isOpenFactAddress ? _c('e-button', {
    staticClass: "link-button",
    attrs: {
      "plus": "",
      "size": "m",
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.isOpenFactAddress = true;
      }
    }
  }, [_vm._v(" Добавить адрес ")]) : _vm._e(), _c('e-collapse', {
    attrs: {
      "show-button": false,
      "value": _vm.isOpenFactAddress
    }
  }, [_vm.isOpenFactAddress ? _c('div', {
    staticClass: "form"
  }, [_c('div', {
    staticClass: "row mb-4"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Страна")]), _c('e-input', {
    attrs: {
      "placeholder": "Страна",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.country,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "country", $$v);
      },
      expression: "form.address_fact.country"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Город")]), _c('e-input', {
    attrs: {
      "placeholder": "Город",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.city,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "city", $$v);
      },
      expression: "form.address_fact.city"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Улица")]), _c('e-input', {
    attrs: {
      "placeholder": "Улица",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.street,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "street", $$v);
      },
      expression: "form.address_fact.street"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Дом")]), _c('e-input', {
    attrs: {
      "placeholder": "Дом",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.house,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "house", $$v);
      },
      expression: "form.address_fact.house"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Квартира/Офис")]), _c('e-input', {
    attrs: {
      "placeholder": "Квартира/Офис",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.apartment,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "apartment", $$v);
      },
      expression: "form.address_fact.apartment"
    }
  })], 1), _c('div', {
    staticClass: "col-4"
  }, [_c('label', [_vm._v("Почтовый индекс")]), _c('e-input', {
    attrs: {
      "placeholder": "Почтовый индекс",
      "type": "text",
      "required": ""
    },
    model: {
      value: _vm.form.address_fact.post_index,
      callback: function ($$v) {
        _vm.$set(_vm.form.address_fact, "post_index", $$v);
      },
      expression: "form.address_fact.post_index"
    }
  })], 1)])]) : _vm._e()])], 1)])]), _c('div', {
    staticClass: "footer-block"
  }, [_c('e-button', {
    attrs: {
      "size": "m",
      "variant": "primary"
    },
    on: {
      "click": _vm.saveManufacturer
    }
  }, [_vm._v(" Сохранить ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }