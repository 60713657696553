<template>
  <b-card
    v-if="products.length"
    no-body
    class="p-3"
  >
    <b-modal
      id="binding-delete-card"
      centered
    >
      <template #modal-header><h5>Подтвердите удаление связи</h5></template>
      <span style="color: #313131; font-size: 16px">
        После удаления товар потерят связь с товаром из внешней системы. Её можно восстановить при новом поступлении
        товара
      </span>
      <template #modal-footer>
        <div style="display: flex">
          <b-button
            variant="primary"
            class="text-nowrap w-auto"
            @click="removeBinding"
          >
            Подтвердить
          </b-button>
          <b-button
            variant="outline-primary"
            @click="hideModal"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-modal>
    <div
      v-b-toggle.mercury-collapse
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <h1>Меркурий</h1>
      <span class="when-opened"
        ><img
          src="/img/icons/arrow-gray.svg"
          width="20"
          height="28"
          style="transition-duration: 0.8s; transition-property: transform"
      /></span>
      <span class="when-closed"
        ><img
          src="/img/icons/arrow-gray.svg"
          width="20"
          height="28"
          style="transform: rotate(180deg); transition-duration: 0.8s; transition-property: transform"
      /></span>
    </div>
    <span
      style="color: #888888; font-size: 18px; font-weight: 500; border-bottom: 1px solid #e1e1e1"
      class="mt-2 mb-2 pb-2"
      >Автоматизированная система для электронной сертификации грузов, за которыми установлен государственный
      ветеринарный контроль на территории РФ.
    </span>
    <div>
      <b-collapse id="mercury-collapse">
        <div
          v-for="product of products"
          :key="product.id"
        >
          <div
            v-b-toggle="`inner-collapse${product.id}`"
            style="display: flex; flex-direction: row; justify-content: space-between"
          >
            <h6 style="color: #313131">{{ product.productItem_name }}</h6>
            <div
              style="display: flex; flex-direction: row; gap: 24px"
              class="collapse-inside"
            >
              <img
                :id="`delete-binding${product.id}`"
                src="/img/icons/trash.svg"
                width="20"
                height="28"
                @click.stop="deleteBinding(product.id)"
              />
              <b-tooltip :target="`delete-binding${product.id}`"> Удалить связь </b-tooltip>
              <span class="when-opened"
                ><img
                  src="/img/icons/arrow-gray.svg"
                  width="20"
                  height="28"
                  style="transition-duration: 0.8s; transition-property: transform"
              /></span>
              <span class="when-closed"
                ><img
                  src="/img/icons/arrow-gray.svg"
                  width="20"
                  height="28"
                  style="transform: rotate(180deg); transition-duration: 0.8s; transition-property: transform"
              /></span>
            </div>
          </div>
          <b-collapse
            :id="`inner-collapse${product.id}`"
            style="border-bottom: 1px solid #e1e1e1"
          >
            <div class="w-75">
              <b-row>
                <b-col>
                  <label>Остатки Меркурий</label>
                  <b-form-input
                    disabled
                    :value="product.volume"
                    size="sm"
                  />
                </b-col>
                <b-col>
                  <label>Упаковка</label>
                  <b-form-input
                    disabled
                    value="Нет сведений"
                  />
                </b-col>
                <b-col>
                  <label>Код в системе Меркурий</label>
                  <b-form-input
                    disabled
                    :value="product.productItem_code ?? 'Нет сведений'"
                  />
                </b-col>
              </b-row>
              <b-row class="mt-2 mb-3">
                <b-col>
                  <label>Тип продукции</label>
                  <b-form-input
                    disabled
                    :value="product.productType ?? 'Нет сведений'"
                  />
                </b-col>
                <b-col>
                  <label>Продукция</label>
                  <b-form-input
                    disabled
                    :value="product.subProduct_guid ?? 'Нет сведений'"
                  />
                </b-col>
                <b-col>
                  <label>Вид продукции</label>
                  <b-form-input
                    disabled
                    :value="product.product_guid ?? 'Нет сведений'"
                  />
                </b-col>
              </b-row>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
  </b-card>
</template>
<script>
  export default {
    name: 'MercuryExtCard',
    props: {
      products: {
        type: Array,
        default: () => []
      },
      product: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        itemToDelete: null
      }
    },
    methods: {
      deleteBinding(id) {
        this.itemToDelete = id
        this.$bvModal.show('binding-delete-card')
      },
      async removeBinding() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../../../operational-processes/gql/mercury/linkMercuryProduct.graphql'),
            variables: {
              ext: this.itemToDelete
            }
          })

          if (data?.LinkMercuryProduct?.status) {
            this.$emit('refresh')
            this.hideModal()
          }
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка отвязки продукта')
        }
      },
      hideModal() {
        this.itemToDelete = null
        this.$bvModal.hide('binding-delete-card')
      }
    }
  }
</script>

<style scoped>
  .collapsed > .when-opened,
  .not-collapsed > .when-closed {
    display: none;
  }

  .collapsed > .collapse-inside > .when-opened,
  .not-collapsed > .collapse-inside > .when-closed {
    display: none;
  }
</style>
