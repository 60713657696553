var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.products.length ? _c('b-card', {
    staticClass: "p-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "binding-delete-card",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function () {
        return [_c('h5', [_vm._v("Подтвердите удаление связи")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('b-button', {
          staticClass: "text-nowrap w-auto",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.removeBinding
          }
        }, [_vm._v(" Подтвердить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_vm._v(" Отмена ")])], 1)];
      },
      proxy: true
    }], null, false, 2400080536)
  }, [_c('span', {
    staticStyle: {
      "color": "#313131",
      "font-size": "16px"
    }
  }, [_vm._v(" После удаления товар потерят связь с товаром из внешней системы. Её можно восстановить при новом поступлении товара ")])]), _c('div', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.crptech-collapse",
      modifiers: {
        "crptech-collapse": true
      }
    }],
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between"
    }
  }, [_c('h1', [_vm._v("ЭДО")]), _c('span', {
    staticClass: "when-opened"
  }, [_c('img', {
    staticStyle: {
      "transition-duration": "0.8s",
      "transition-property": "transform"
    },
    attrs: {
      "src": "/img/icons/arrow-gray.svg",
      "width": "20",
      "height": "28"
    }
  })]), _c('span', {
    staticClass: "when-closed"
  }, [_c('img', {
    staticStyle: {
      "transform": "rotate(180deg)",
      "transition-duration": "0.8s",
      "transition-property": "transform"
    },
    attrs: {
      "src": "/img/icons/arrow-gray.svg",
      "width": "20",
      "height": "28"
    }
  })])]), _c('span', {
    staticClass: "mt-2 pb-2",
    staticStyle: {
      "color": "#888888",
      "font-size": "18px",
      "font-weight": "500",
      "border-bottom": "1px solid #e1e1e1"
    }
  }, [_vm._v("Национальная система цифровой маркировки и прослеживания товаров. Данные загружаются из государственной информационной системы. ")]), _c('div', [_c('b-collapse', {
    attrs: {
      "id": "crptech-collapse"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c('div', {
      key: product.id,
      staticStyle: {
        "border-bottom": "1px solid rgb(225, 225, 225)",
        "padding": "12px 0 12px 0"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "justify-content": "space-between",
        "align-items": "center"
      }
    }, [_c('div', {
      staticClass: "w-75"
    }, [_c('b-row', [_c('b-col', [_c('h6', {
      staticStyle: {
        "color": "#313131",
        "margin-bottom": "0px"
      }
    }, [_vm._v(" " + _vm._s(product.name) + " ")])])], 1)], 1), _c('h5', {
      staticStyle: {
        "color": "#bcbcbc"
      }
    }, [_vm._v(_vm._s(product.productItem_name))]), _c('div', {
      staticClass: "collapse-inside",
      staticStyle: {
        "display": "flex",
        "flex-direction": "row",
        "gap": "24px"
      }
    }, [_c('img', {
      attrs: {
        "id": `delete-binding${product.id}`,
        "src": "/img/icons/trash.svg",
        "width": "20",
        "height": "28"
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.deleteBinding(product.id);
        }
      }
    }), _c('b-tooltip', {
      attrs: {
        "target": `delete-binding${product.id}`
      }
    }, [_vm._v(" Удалить связь ")])], 1)])]);
  }), 0)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }