var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "package-modal",
      "centered": "",
      "small": ""
    },
    on: {
      "shown": _vm.checkPkg
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var _vm$pkg;

        var close = _ref.close;
        return [_c('h5', [_vm._v(_vm._s((_vm$pkg = _vm.pkg) !== null && _vm$pkg !== void 0 && _vm$pkg.id ? 'Изменить' : 'Новая упаковка'))]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": function ($event) {
              return _vm.closes(close);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var close = _ref2.close;
        return [_c('div', {
          staticClass: "d-flex w-50 justify-content-between"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.createPckg
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function ($event) {
              return _vm.closes(close);
            }
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Количество")]), _c('b-form-input', {
    attrs: {
      "type": "number",
      "autofocus": ""
    },
    model: {
      value: _vm.pckg.value,
      callback: function ($$v) {
        _vm.$set(_vm.pckg, "value", $$v);
      },
      expression: "pckg.value"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "justify-content": "space-between",
      "gap": "16px"
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Длина, см")]), _c('b-form-input', {
    model: {
      value: _vm.pckg.length,
      callback: function ($$v) {
        _vm.$set(_vm.pckg, "length", $$v);
      },
      expression: "pckg.length"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Высота, см")]), _c('b-form-input', {
    model: {
      value: _vm.pckg.height,
      callback: function ($$v) {
        _vm.$set(_vm.pckg, "height", $$v);
      },
      expression: "pckg.height"
    }
  })], 1), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v("Ширина, см")]), _c('b-form-input', {
    model: {
      value: _vm.pckg.width,
      callback: function ($$v) {
        _vm.$set(_vm.pckg, "width", $$v);
      },
      expression: "pckg.width"
    }
  })], 1)]), _c('div', {
    staticClass: "form-group barcode-group"
  }, [_c('label', [_vm._v("Штрих-код")]), _c('b-form-input', {
    staticClass: "scan-circle",
    class: {
      error: _vm.barcode_error
    },
    attrs: {
      "type": "number",
      "required": "",
      "placeholder": "00000000000"
    },
    model: {
      value: _vm.pckg.barcode.barcode,
      callback: function ($$v) {
        _vm.$set(_vm.pckg.barcode, "barcode", $$v);
      },
      expression: "pckg.barcode.barcode"
    }
  }), _c('img', {
    staticClass: "generate-barcode",
    attrs: {
      "src": "/img/icons/icon_scan_circle.svg",
      "alt": "Можно считать сканером"
    }
  }), _vm.barcode_error === 1 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Необходимо ввести штрихкод ")]) : _vm._e(), _vm.barcode_error === 2 ? _c('p', {
    staticClass: "error mt-1"
  }, [_vm._v(" Такой штрихкод уже существует ")]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }