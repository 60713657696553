<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="1.35456"
      cy="8.01838"
      r="1.32917"
      :fill="fill"
    />
    <circle
      cx="8.00105"
      cy="8.01838"
      r="1.32917"
      :fill="fill"
    />
    <circle
      cx="14.6475"
      cy="8.01838"
      r="1.32917"
      :fill="fill"
    />
  </svg>
</template>

<script>
  export default {
    name: 'MoreSvg',
    props: {
      fill: { type: String, default: () => '#BCBCBC' }
    }
  }
</script>

<style scoped></style>
