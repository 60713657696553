var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-table-simple', {
    attrs: {
      "small": "",
      "responsive": "",
      "table-class": "package-table"
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', {
    staticClass: "center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Наименование ")]), _c('b-th', {
    staticClass: "center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v(" Габаритные размеры, см. ")]), _c('b-th', {
    staticClass: "center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Штрих-код упаковки ")]), _c('b-th', {
    staticClass: "center",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Действия ")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "center"
  }, [_vm._v("Длина")]), _c('b-th', {
    staticClass: "center"
  }, [_vm._v("Высота")]), _c('b-th', {
    staticClass: "center",
    staticStyle: {
      "border-right": "1px solid #e1e1e1"
    }
  }, [_vm._v(" Ширина ")])], 1), _c('b-tr')], 1), _c('b-tbody', [!_vm.packages.length ? _c('b-tr', [_c('b-td', {
    staticClass: "center",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v(" Нет упаковок ")])], 1) : _vm._e(), _vm._l(_vm.packages, function (pckg, index) {
    var _pckg$length, _pckg$height, _pckg$width, _pckg$barcode$barcode, _pckg$barcode;

    return _c('b-tr', {
      key: pckg.id
    }, [_c('b-td', [_vm._v(" Упаковка 1*" + _vm._s(pckg.value) + " ")]), _c('b-td', {
      staticClass: "center"
    }, [_vm._v(" " + _vm._s((_pckg$length = pckg.length) !== null && _pckg$length !== void 0 ? _pckg$length : '--'))]), _c('b-td', {
      staticClass: "center"
    }, [_vm._v(" " + _vm._s((_pckg$height = pckg.height) !== null && _pckg$height !== void 0 ? _pckg$height : '--') + " ")]), _c('b-td', {
      staticClass: "center"
    }, [_vm._v(" " + _vm._s((_pckg$width = pckg.width) !== null && _pckg$width !== void 0 ? _pckg$width : '--') + " ")]), _c('b-td', {
      staticClass: "center"
    }, [_vm._v(" " + _vm._s((_pckg$barcode$barcode = (_pckg$barcode = pckg.barcode) === null || _pckg$barcode === void 0 ? void 0 : _pckg$barcode.barcode) !== null && _pckg$barcode$barcode !== void 0 ? _pckg$barcode$barcode : '--') + " ")]), _c('b-td', {
      staticClass: "center"
    }, [_c('b-dropdown', {
      attrs: {
        "no-caret": "",
        "variant": "none"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('img', {
            attrs: {
              "src": "/img/icons/more.svg"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.editPkg(pckg);
        }
      }
    }, [_vm._v(" Редактировать ")]), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.removePckg(pckg, index);
        }
      }
    }, [_vm._v("Удалить")])], 1)], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }