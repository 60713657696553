<template>
  <b-modal
    id="add-scales-modal"
    hide-footer
    hide-header
    centered
  >
    <div>
      <div class="title-block">
        <p class="title">Добавление товара на весы</p>
        <e-button @click="hideModal()">
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </e-button>
      </div>
    </div>
    <div>
      <div
        v-if="!isEanWeight"
        class="form-group barcode-group"
      >
        <label>
          Весовой штрих-код
          <span class="color-red">*</span>
        </label>
        <div
          class="b-generate-bc cursor"
          @click="generateBarcode"
        >
          Сгенерировать штрих-код
        </div>
        <b-form-input
          v-model="barcode_item.barcode.barcode"
          autofocus
          class="scan-circle"
          :class="{ error: barcode_error }"
          type="number"
          required
          placeholder="00000000000"
        />
        <img
          class="generate-barcode"
          src="/img/icons/icon_scan_circle.svg"
          alt="Можно считать сканером"
        />
        <p
          v-if="barcode_error === 1"
          class="error mt-1"
        >
          Необходимо ввести штрихкод
        </p>
        <p
          v-if="barcode_error === 2"
          class="error mt-1"
        >
          Такой штрихкод уже существует
        </p>
      </div>
      <div class="form-group">
        <label>
          Списки
          <span class="color-red">*</span>
        </label>
        <e-select
          v-model="selected_scales"
          placeholder="Выберите список"
          :error="scalesError"
          :options="list_options"
        />
      </div>
    </div>
    <div class="footer-block">
      <e-button
        size="m"
        :loading="loading"
        :disabled="loading"
        variant="primary"
        @click="save"
      >
        Сохранить
      </e-button>
      <e-button
        size="m"
        variant="outline-primary"
        @click="hideModal"
      >
        Отмена
      </e-button>
    </div>
  </b-modal>
</template>

<script>
  import * as R from 'ramda'
  import { mapGetters } from 'vuex'
  import { ProductModel } from '@/models/product.model'

  export default {
    name: 'AddScalesModal',
    apollo: {
      PluLists: {
        query: require('../../settings/gql/PluLists.graphql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            input: {
              branch: this.currentBranch.id,
              visible: true
            }
          }
        },
        result({ data }) {
          this.scales_list = data?.PluLists ?? []
        }
      },
      GenerateBarcode: {
        query: require('../gql/GenerateBarcode.gql'),
        fetchPolicy: 'no-cache',
        variables() {
          return {
            ean: 'weight'
          }
        },
        result({ data }) {
          this.barcode_item.barcode.barcode = data?.GenerateBarcode?.barcode ?? ''
          this.ean_generate = true
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        },
        skip() {
          return this.ean_generate
        }
      }
    },
    props: {
      product: ProductModel,
      barcodes: {
        type: Array,
        default: () => []
      },
      product_id: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        loading: false,
        scalesError: false,
        scales_list: [],
        selected_scales: [],
        barcode_error: null,
        barcode_item: {
          value: 1,
          barcode: {
            barcode: ''
          }
        },
        ean_generate: true,
        generated: false
      }
    },
    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch'
      }),
      isEanWeight() {
        return this.barcodes.some((obj) => obj?.barcode?.barcode?.length === 7)
      },
      list_options() {
        return (
          this.scales_list.filter((obj) =>
            this.product.plu ? this.product.plu?.some((item) => item.list.id !== obj.id) : true
          ) ?? []
        )
      }
    },
    watch: {
      'barcode_item.barcode.barcode'() {
        if (this.barcode_error) this.barcode_error = false
      },
      selected_scales() {
        if (this.scalesError) {
          this.scalesError = false
        }
      }
    },
    mounted() {
      console.log(this.product.plu)
    },
    methods: {
      save() {
        this.createPlu()
      },
      resetForm() {
        this.barcode_item.value = 1
        this.barcode_item.barcode.barcode = ''
        this.barcode_error = null
        this.generated = false
        this.selected_scales = []
        this.loading = false
      },
      hideModal() {
        this.$bvModal.hide('add-scales-modal')
      },
      async createPlu() {
        this.loading = true
        const barcode = R.pick(['barcode_item'], this)
        if (!this.barcode_item.barcode.barcode?.length && !this.isEanWeight) {
          this.barcode_error = 1
          this.loading = false
          return
        }
        if (!this.selected_scales?.length) {
          this.scalesError = true
          this.loading = false
          return this.$noty.error('Необходимо выбрать список')
        }
        if (!this.isEanWeight) {
          try {
            const { data } = await this.$apollo.mutate({
              mutation: require('../gql/checkBarcode.gql'),
              variables: {
                barcode: this.barcode_item.barcode.barcode
              }
            })
            if (data) {
              const statusOnServe = data?.CheckBarcode?.status ?? false

              const statusOnUser = (barcodes) => {
                let status = false
                barcodes.forEach((item) => {
                  if (item.barcode.barcode === this.barcode_item.barcode.barcode) status = true
                })
                return status
              }
              if (statusOnServe || statusOnUser(this.barcodes)) return (this.barcode_error = 2)
              this.$emit('add-barcode', barcode)
              this.barcode_error = false
              this.loading = false
            }
          } catch (e) {
            this.loading = false
            console.error('error: ', e)
            return this.$noty.error('Ошибка при создании штрих-кода')
          }
        }
        try {
          await this.$apollo.mutate({
            mutation: require('../../settings/gql/CreatePlu.graphql'),
            variables: {
              input: {
                branch: this.currentBranch.id,
                product: this.product_id,
                list: this.selected_scales[0].id
              }
            }
          })
        } catch (e) {
          console.error(e)
          this.loading = false
          return this.$noty.error('Ошибка при создании PLU кода')
        }
        this.loading = false
        this.resetForm()
        this.hideModal()
        this.$emit('refetch')
      },
      generateBarcode() {
        this.ean_generate = false
        this.generated = true
        this.$apollo.queries.GenerateBarcode.refetch()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .barcode-group {
    position: relative;

    .b-generate-bc {
      font-weight: 400;
      font-size: 14px;
      color: #00a3ff;
      position: absolute;
      top: 0;
      right: 0;
      text-decoration: underline;
    }

    img.generate-barcode {
      position: absolute;
      top: 30px;
      right: 10px;
    }
  }
  .modal-footer {
    justify-content: flex-start !important;

    button {
      width: auto !important;
    }
  }
  .title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .title {
      margin: 0;
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    button {
      padding: 0;
    }
  }
  .footer-block {
    display: flex;
    margin-bottom: 22px;
    margin-top: 32px;
    button {
      &:nth-child(2) {
        margin-left: 8px;
      }
    }
  }
</style>
