<template>
  <b-table-simple
    small
    responsive
    table-class="package-table"
  >
    <b-thead>
      <b-tr>
        <b-th
          class="center"
          rowspan="2"
        >
          Наименование
        </b-th>
        <b-th
          class="center"
          colspan="3"
        >
          Габаритные размеры, см.
        </b-th>
        <b-th
          class="center"
          rowspan="2"
        >
          Штрих-код упаковки
        </b-th>
        <b-th
          rowspan="2"
          class="center"
        >
          Действия
        </b-th>
      </b-tr>
      <b-tr>
        <b-th class="center">Длина</b-th>
        <b-th class="center">Высота</b-th>
        <b-th
          class="center"
          style="border-right: 1px solid #e1e1e1"
        >
          Ширина
        </b-th>
      </b-tr>
      <b-tr />
    </b-thead>
    <b-tbody>
      <b-tr v-if="!packages.length">
        <b-td
          colspan="6"
          class="center"
        >
          Нет упаковок
        </b-td>
      </b-tr>
      <b-tr
        v-for="(pckg, index) of packages"
        :key="pckg.id"
      >
        <b-td> Упаковка 1*{{ pckg.value }} </b-td>
        <b-td class="center"> {{ pckg.length ?? '--' }}</b-td>
        <b-td class="center"> {{ pckg.height ?? '--' }} </b-td>
        <b-td class="center"> {{ pckg.width ?? '--' }} </b-td>
        <b-td class="center"> {{ pckg.barcode?.barcode ?? '--' }} </b-td>
        <b-td class="center">
          <b-dropdown
            no-caret
            variant="none"
          >
            <template #button-content>
              <img src="/img/icons/more.svg" />
            </template>
            <b-dropdown-item @click="editPkg(pckg)"> Редактировать </b-dropdown-item>
            <b-dropdown-item @click="removePckg(pckg, index)">Удалить</b-dropdown-item>
          </b-dropdown>
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<script>
  export default {
    name: 'PackageTable',
    props: {
      packages: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      editPkg(pkg) {
        this.$emit('edit_package', pkg)
      },
      async removePckg(pkg, index) {
        this.$emit('remove_package', { index, item: pkg })
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .package-table > thead > tr > th {
      padding: 8px !important;
    }
  }

  .package-table > thead > tr > th {
    padding: 8px !important;
  }
</style>
