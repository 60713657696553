<template>
  <b-card
    v-if="products.length"
    no-body
    class="p-3"
  >
    <b-modal
      id="binding-delete-card"
      centered
    >
      <template #modal-header><h5>Подтвердите удаление связи</h5></template>
      <span style="color: #313131; font-size: 16px">
        После удаления товар потерят связь с товаром из внешней системы. Её можно восстановить при новом поступлении
        товара
      </span>
      <template #modal-footer>
        <div style="display: flex">
          <b-button
            variant="primary"
            class="text-nowrap w-auto"
            @click="removeBinding"
          >
            Подтвердить
          </b-button>
          <b-button
            variant="outline-primary"
            @click="hideModal"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-modal>
    <div
      v-b-toggle.crptech-collapse
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <h1>ЭДО</h1>
      <span class="when-opened"
        ><img
          src="/img/icons/arrow-gray.svg"
          width="20"
          height="28"
          style="transition-duration: 0.8s; transition-property: transform"
      /></span>
      <span class="when-closed"
        ><img
          src="/img/icons/arrow-gray.svg"
          width="20"
          height="28"
          style="transform: rotate(180deg); transition-duration: 0.8s; transition-property: transform"
      /></span>
    </div>
    <span
      style="color: #888888; font-size: 18px; font-weight: 500; border-bottom: 1px solid #e1e1e1"
      class="mt-2 pb-2"
      >Национальная система цифровой маркировки и прослеживания товаров. Данные загружаются из государственной
      информационной системы.
    </span>
    <div>
      <b-collapse id="crptech-collapse">
        <div
          v-for="product of products"
          :key="product.id"
          style="border-bottom: 1px solid rgb(225, 225, 225); padding: 12px 0 12px 0"
        >
          <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div class="w-75">
              <b-row>
                <b-col>
                  <h6 style="color: #313131; margin-bottom: 0px">
                    {{ product.name }}
                  </h6>
                </b-col>
              </b-row>
            </div>
            <h5 style="color: #bcbcbc">{{ product.productItem_name }}</h5>
            <div
              style="display: flex; flex-direction: row; gap: 24px"
              class="collapse-inside"
            >
              <img
                :id="`delete-binding${product.id}`"
                src="/img/icons/trash.svg"
                width="20"
                height="28"
                @click.stop="deleteBinding(product.id)"
              />
              <b-tooltip :target="`delete-binding${product.id}`"> Удалить связь </b-tooltip>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </b-card>
</template>
<script>
  export default {
    name: 'CrptechExtCard',
    props: {
      products: {
        type: Array,
        default: () => []
      },
      product: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        itemToDelete: null
      }
    },
    methods: {
      deleteBinding(id) {
        this.itemToDelete = id
        this.$bvModal.show('binding-delete-card')
      },
      async removeBinding() {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: require('../../../gql/ReturnExtIdToOriginal.graphql'),
            variables: {
              input: {
                external_id: this.itemToDelete,
                product: this.product
              }
            }
          })

          if (data?.ReturnExtIdToOriginal?.status) {
            this.$emit('refresh')
            this.hideModal()
          }
        } catch (e) {
          console.error(e)
          this.$noty.error('Ошибка отвязки продукта')
        }
      },
      hideModal() {
        this.itemToDelete = null
        this.$bvModal.hide('binding-delete-card')
      }
    }
  }
</script>

<style scoped>
  .collapsed > .when-opened,
  .not-collapsed > .when-closed {
    display: none;
  }

  .collapsed > .collapse-inside > .when-opened,
  .not-collapsed > .collapse-inside > .when-closed {
    display: none;
  }
</style>
