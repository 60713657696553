<template>
  <b-modal
    id="modal-no-barcode"
    class="modal-no-barcode"
    style="max-width: 400px"
    hide-header
    hide-footer
    centered
  >
    <div class="title-block">
      <p class="title">У товара отсутствует штрих-код</p>
      <b-button
        class="close"
        @click="hideModal()"
      >
        <img
          src="/img/icons/modal_close.svg"
          alt=""
        />
      </b-button>
    </div>
    <p class="description">Добавьте штрих-код, чтобы товар мог загрузиться на кассу и весы</p>
    <div class="dont-show">
      <b-form-checkbox
        id="checkbox-no-barcode"
        v-model="status"
        name="checkbox-no-barcode"
      />
      <p @click="status = !status">Не показывать больше</p>
    </div>
    <div class="button-block">
      <b-button
        variant="primary"
        @click="addBarcode"
      >
        Добавить штрих-код
      </b-button>
      <b-button
        variant="outline-primary"
        @click="hideModal()"
      >
        Пропустить
      </b-button>
    </div>
  </b-modal>
</template>
<script>
  export default {
    name: 'NoBarcode',
    props: {
      modal_show: Boolean
    },
    data() {
      return {
        status: false
      }
    },
    updated() {},
    methods: {
      hideModal() {
        if (this.status) {
          localStorage.setItem('no-barcode', true)
        }
        this.$bvModal.hide('modal-no-barcode')
        this.$emit('handlerSaveProduct')
      },

      showModal() {
        if (!localStorage.getItem('no-barcode')) {
          this.$bvModal.show('modal-no-barcode')
        }
      },
      addBarcode() {
        this.$bvModal.hide('modal-no-barcode')
        this.$emit('showAddBarcode')
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .modal-dialog {
    width: 408px !important;
    min-width: 408px !important;
    max-width: 408px !important;
  }
  #modal-no-barcode {
    text-align: center;
    .title {
      font-family: 'Gilroy-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #313131;
    }
    .dont-show {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
      margin-bottom: 23px;
      p {
        cursor: pointer;
        margin: 0;
        font-family: 'Gilroy-Regular', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #888888;
      }
    }
  }
  .button-block {
    margin-bottom: 22px;
    display: flex;
    button {
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }

  .title-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  button.close {
    padding: 0;
    width: 24px;
    height: 24px;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
  .description {
    font-family: 'Gilroy-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #313131;
    margin-bottom: 18px;
  }
</style>
