var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    staticClass: "modal-no-barcode",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "id": "modal-no-barcode",
      "hide-header": "",
      "hide-footer": "",
      "centered": ""
    }
  }, [_c('div', {
    staticClass: "title-block"
  }, [_c('p', {
    staticClass: "title"
  }, [_vm._v("У товара отсутствует штрих-код")]), _c('b-button', {
    staticClass: "close",
    on: {
      "click": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/icons/modal_close.svg",
      "alt": ""
    }
  })])], 1), _c('p', {
    staticClass: "description"
  }, [_vm._v("Добавьте штрих-код, чтобы товар мог загрузиться на кассу и весы")]), _c('div', {
    staticClass: "dont-show"
  }, [_c('b-form-checkbox', {
    attrs: {
      "id": "checkbox-no-barcode",
      "name": "checkbox-no-barcode"
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }), _c('p', {
    on: {
      "click": function ($event) {
        _vm.status = !_vm.status;
      }
    }
  }, [_vm._v("Не показывать больше")])], 1), _c('div', {
    staticClass: "button-block"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addBarcode
    }
  }, [_vm._v(" Добавить штрих-код ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.hideModal();
      }
    }
  }, [_vm._v(" Пропустить ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }